<template>
  <div>
    <Loader v-if="!addresses"></Loader>
    <div v-else>
      <div class="main-group">
        <label class="container flex">
          {{ $t("Deliver to Home") }}
          <input
            type="radio"
            checked="checked"
            name="wheretoship"
            @change="outerRadio($event)"
          />
          <span class="checkmark"></span>
          <button @click="addNewAddressClicked" class="blue2 modal-opener">
            {{ $t("Add Address") }}
          </button>
        </label>
      </div>

      <div class="sub-group">
        <label class="container" v-for="address in addresses" :key="address.id">
          <div>
            {{ address.name }}
            <p>{{ address.description }}</p>
          </div>

          <input
            type="radio"
            name="address"
            :disabled="disable"
            v-model="selectedAddress"
            @change="SelectAddress"
            :value="address"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <!-- <div class="main-group">
        <label class="container"
          >الاستلام من الفرع
          <input
            type="radio"
            name="wheretoship"
            value="office"
            @change="outerRadio($event);"
          />
          <span class="checkmark"></span>
        </label>
      </div> -->

      <div v-if="addNewAddress" v-click-outside-app="close">
        <transition name="model">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal">
                <img src="../assets/adresses.svg" alt="" />
                <h3 class="blue">
                  {{ $t("Add new address") }}
                </h3>
                <form @submit.prevent="addCityClicked()">
                  <input
                    type="text"
                    class="input"
                    :placeholder="$t('Address name')"
                    required
                    v-model="addressName"
                  />
                  <button type="submit" class="input blue2-back white">
                    {{ $t("Choose City") }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div v-if="addCity" v-click-outside-app="close">
        <transition name="model">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal">
                <img src="../assets/adresses.svg" alt="" />
                <h3 class="blue">
                  {{ $t("Add new address") }}
                </h3>
                <form @submit.prevent="addMapPinClicked()">
                  <select
                    v-model="selectedCity"
                    name="cities"
                    class="input"
                    id="cities"
                    required
                  >
                    <option value="" disabled selected>
                      {{ $t("Choose City") }}
                    </option>
                    <option
                      v-for="city in this.$store.state.auth.cities"
                      :key="city.id"
                      :value="city.id"
                    >
                      {{ city.name }}
                    </option>
                  </select>
                  <button type="submit" class="input blue2-back white">
                    {{ $t("Get location on map") }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div v-if="addMapPin" v-click-outside-app="close">
        <transition name="model">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal">
                <img src="../assets/adresses.svg" alt="" />
                <h3 class="blue">
                  {{ $t("Add new address") }}
                </h3>
                <GoogleMap
                  @gotlocation="gotlocation"
                  @gotplace="gotplace"
                  :center="center"
                  :zoom="12"
                  style="width: 100%; height: 400px"
                ></GoogleMap>
                <button
                  class="input blue2-back white"
                  @click="confirmNewAddress"
                >
                  {{ $t("Confirm location") }}
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Loader from "./Loader.vue";
  import GoogleMap from "./GoogleMap.vue";

  export default {
    name: "Addresses",
    components: { Loader, GoogleMap },
    data() {
      return {
        addNewAddress: false,
        addCity: false,
        addMapPin: false,
        disable: false,
        addresses: null,
        center: { lat: 45.508, lng: -73.587 },
        selectedAddress: null,
        newAddress: null,
        newCoordinates: null,
        addressName: "",
        selectedCity: "",
      };
    },
    methods: {
      SelectAddress() {
        this.$store.state.auth.selectedAddress = this.selectedAddress;
        this.$store.state.auth.selectedCityID = this.selectedAddress.city_id;
        this.$store.state.auth.delivery = 0;
      },

      addNewAddressClicked() {
        this.addNewAddress = true;
      },
      addCityClicked() {
        this.addCity = true;
        this.addNewAddress = false;
      },
      addMapPinClicked() {
        this.addMapPin = true;
        this.addCity = false;
      },
      close() {
        this.addMapPin = false;
        this.addNewAddress = false;
        this.addCity = false;
      },
      outerRadio(event) {
        if (event.target.value == "office") {
          this.disable = true;
        } else {
          this.disable = false;
        }
      },
      confirmNewAddress() {
        let data = {
          name: this.addressName,
          description: this.newAddress,
          lat: `${this.newCoordinates.position.lat}`,
          lng: `${this.newCoordinates.position.lng}`,
          city_id: `${this.selectedCity}`,
        };
        axios
          .post(`${this.$store.state.auth.rootURL}address`, data, {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          })
          .then((response) => {
            if (response.status) {
              this.addMapPin = false;
            }
          });
      },
      gotplace(place) {
        this.newAddress = place;
      },
      gotlocation(location) {
        this.newCoordinates = location;
      },
    },
    mounted() {
      // this.$store.state.auth.selectedAddress = null;
      this.$store.state.auth.delivery = 0;
      //****************************** getting addresses ******************************
      axios
        .get(`${this.$store.state.auth.rootURL}address`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.token}`,
          },
        })
        .then((response) => {
          this.addresses = response.data.data;
        });
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/addresses.scss";
</style>
