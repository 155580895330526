<template>
  <div>
    <Loader v-if="!$store.state.auth.coursesbyTrainer"></Loader>
    <div v-else>
      <h2 class="blue2-back white title">{{ $t("Trainers") }}</h2>
      <div class="slider">
        <vueper-slides
          arrows
          :bullets="false"
          :touchable="false"
          rtl
          :arrowsOutside="true"
          class="no-shadow"
          :visible-slides="6"
          :slide-ratio="1 / 4"
          slide-multiple
          :gap="3"
          :dragging-distance="200"
          :breakpoints="breakpoints"
        >
          <vueper-slide
            v-for="trainer in $store.state.auth.trainers"
            :key="trainer.id"
            ><template v-slot:content
              ><div
                @click="trainerClicked(trainer)"
                :id="trainer.id"
                class="trainerscontainer"
              >
                <img
                  :src="trainer.photo"
                  :class="{
                    active: trainer.id == $store.state.auth.activeTrainer.id,
                  }"
                />
                <h2>{{ trainer.name }}</h2>
              </div></template
            ></vueper-slide
          >
        </vueper-slides>
      </div>
      <h2 class="white orange-back title">
        {{ $t("Courses of") }}
        <span class="black">{{ $store.state.auth.activeTrainer.name }}</span>
      </h2>
      <div class="flex items-container wrap">
        <SingleTraining
          v-for="course in $store.state.auth.coursesbyTrainer"
          :key="course.id"
          :course="course"
        ></SingleTraining>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import Loader from "../components/Loader";
  import { VueperSlides, VueperSlide } from "vueperslides";
  import "vueperslides/dist/vueperslides.css";
  import SingleTraining from "../components/SingleTraining";

  export default {
    name: "Trainers",
    components: { Loader, SingleTraining, VueperSlides, VueperSlide },
    data() {
      return {
        breakpoints: {
          850: {
            visibleSlides: 5,
            slideRatio: 1 / 3.5,
          },
          650: {
            visibleSlides: 4,
            slideRatio: 1 / 3,
          },

          500: {
            visibleSlides: 2,
            slideRatio: 1 / 1.4,
          },
        },
      };
    },
    methods: {
      ...mapActions(["getCoursesbyTrainer", "getTrainers"]),
      trainerClicked(trainer) {
        this.getCoursesbyTrainer(trainer);
      },
    },
    mounted() {
      this.getTrainers();
    },
  };
</script>
<style lang="sass" scoped>
  @import "../styles/globalsVars.scss"
  @import "../styles/trainers.scss"
</style>
