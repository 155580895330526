<template>
  <div class="flex container center">
    <form
      class="flex col"
      @submit.prevent="
        checkMail({
          email: email,
        })
      "
    >
      <h1>
        <router-link to="/">
          <img src="../assets/logo/full-logo.png" alt="logo" />
        </router-link>
      </h1>
      <h2 class="blue">{{ $t("Forgot Password") }}</h2>
      <p>
        {{ $t("We'll send code to your mail to reset your password") }}
      </p>
      <input
        class="input"
        type="email"
        name="email"
        :placeholder="$t('Email')"
        v-model="email"
        required
      />

      <button class="input blue2-back white" type="submit">{{ $t("Next") }}</button>
    </form>
    <!-- <div class="sign forgot-password"></div> -->
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    name: "ForgotPassword",
    components: {},
    data() {
      return {
        email: "",
      };
    },
    methods: { ...mapActions(["checkMail"]) },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/sign.scss";
</style>
