<template>
  <router-link
    :to="{ name: 'shop', params: { id: shop.id } }"
    class="shop-container item animate__animated animate__fadeIn"
  >
    <div class="img-container flex">
      <img :src="shop.photo" alt="shop image" />
      <div
        class="fav"
        @click="
          toogleFav({ store_id: shop.id });
          toogleLike($event);
        "
        :class="shop.like ? 'faved' : 'not-faved'"
        v-if="isLoggedIn"
      ></div>
    </div>
    <div class="data-container">
      <div class="flex align">
        <h3>{{ shop.name }}</h3>
        <span class="yellow small">
          <Rate :rate="shop.rate"></Rate>
        </span>
      </div>

      <p class="grey">{{ $t("Category") }} {{ shop.category }}</p>
    </div></router-link
  >
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import Rate from "./Rate";

  export default {
    name: "SingleShop",
    components: { Rate },
    props: ["shop"],
    computed: mapGetters(["isLoggedIn"]),
    methods: {
      ...mapActions(["toogleFav"]),
      toogleLike(e) {
        e.preventDefault();
        if (e.target.className == "fav faved") {
          e.target.setAttribute("class", "fav not-faved");
        } else {
          e.target.setAttribute("class", "fav faved");
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import "../styles/globalsVars.scss"
  @import "../styles/singleShop.scss"
</style>
