<template>
  <div>
    <div class="splitter">
      <div class="inner"></div>
    </div>
    <h2 class="title padding2">
      {{ $t("My wallet") }}
    </h2>
    <div class="flex col">
      <div class="flex center align">
        <img src="../assets/calc.svg" alt="calculator img" />
        <h3 class="center">
          {{ $t("Current balance") }}
          <br />
          <span class="blue"
            >1000 <br />
            {{ $t("SR") }}</span
          >
        </h3>
      </div>
      <button class="input orange3-back white">
        {{ $t("Recharge balance") }}
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Wallet",
    components: {},
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/wallet.scss";
</style>
