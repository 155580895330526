<template>
  <div class="flex container center">
    <form
      class="flex col"
      @submit.prevent="
        signup({
          email: email,
          password: password,
          name: name,
          lastname: lastname,
          phone: phone,
          password_confirmation: password_confirmation,
        })
      "
    >
      <h1>
        <router-link to="/">
          <img src="../assets/logo/full-logo.png" alt="logo" />
        </router-link>
      </h1>
      <h2 class="blue">{{ $t("Sign Up") }}</h2>
      <input
        v-model="email"
        class="input"
        type="email"
        name="email"
        :placeholder="$t('Email')"
        required
      />
      <input
        v-model="name"
        class="input"
        type="text"
        name=""
        :placeholder="$t('First name')"
        required
      />
      <input
        v-model="lastname"
        class="input"
        type="text"
        name=""
        :placeholder="$t('Last name')"
        required
      />
      <input
        v-model="phone"
        class="input"
        type="text"
        name=""
        id=""
        :placeholder="$t('Mobile')"
        required
      />
      <input
        v-model="password"
        class="input"
        type="password"
        name=""
        :placeholder="$t('Password')"
        required
      />
      <input
        v-model="password_confirmation"
        class="input"
        type="password"
        name=""
        :placeholder="$t('Password Confirmation')"
        required
      />
      <button class="input blue2-back white" type="submit">
        {{ $t("Sign Up") }}
      </button>
      <div class="flex space-btw">
        <p></p>
        <p>
          <router-link to="/" class="orange2">
            {{ $t("Skip") }}
          </router-link>
        </p>
      </div>
    </form>
    <!-- <div class="sign mail-sign"></div> -->
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  export default {
    name: "SignUpWMail",
    components: {},
    data() {
      return {
        email: "",
        password: "",
        name: "",
        lastname: "",
        phone: "",
        password_confirmation: "",
      };
    },
    methods: {
      ...mapActions(["signup"]),
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/sign.scss";
</style>
