<template>
  <div>
    <Loader v-if="!$store.state.auth.profiledata"></Loader>
    <div v-else>
      <div class="splitter">
        <div class="inner"></div>
      </div>
      <div class="flex space-btw padding">
        <div class="profile-data flex col align">
          <h2 class="title">{{ $t("Personal informations") }}</h2>
          <ul>
            <li>
              <h3>
                {{ $t("First name") }}
              </h3>
              <h3>{{ this.$store.state.auth.profiledata.name }}</h3>
            </li>
            <li>
              <h3>
                {{ $t("Last name") }}
              </h3>
              <h3>{{ this.$store.state.auth.profiledata.lastname }}</h3>
            </li>
            <li>
              <h3>
                {{ $t("Email") }}
              </h3>
              <h3>{{ this.$store.state.auth.profiledata.email }}</h3>
            </li>
            <li>
              <h3>
                {{ $t("Mobile") }}
              </h3>
              <h3>{{ this.$store.state.auth.profiledata.phone }}</h3>
            </li>
          </ul>
          <router-link to="/editprofile" class="edit-button white orange-back"
            > {{ $t("Edit profile picture") }}</router-link
          >
          <router-link to="/ChangePassword" class="change-pass blue2">
            {{ $t("Reset Password") }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import Loader from "../components/Loader";
  export default {
    name: "Profile",
    components: { Loader },

    methods: {
      ...mapActions(["getProfileData"]),
    },
    mounted() {
      this.getProfileData();
    },
  };
</script>

<style lang="sass" scoped>
  @import "../styles/globalsVars.scss"
  @import "../styles/profile.scss"
</style>
