<template>
  <div class="flex container center">
    <form
      class="flex col"
      @submit.prevent="
        checkCode({
          email: $store.state.auth.mail,
          code: code,
        })
      "
    >
      <h1>
        <router-link to="/">
          <img src="../assets/logo/full-logo.png" alt="logo" />
        </router-link>
      </h1>
      <h2 class="blue">
        
        {{ $t("Confirmation Code") }}
      </h2>
      <p>
        {{ $t("Enter the 4 digits code you recived on mail") }}
      </p>
      <!-- <div class="flex space-btw code-container">
        <input
          type="text"
          maxLength="1"
          size="1"
          min="0"
          max="9"
          pattern="[0-9]{1}"
        />
        <input
          type="text"
          maxLength="1"
          size="1"
          min="0"
          max="9"
          pattern="[0-9]{1}"
        /><input
          type="text"
          maxLength="1"
          size="1"
          min="0"
          max="9"
          pattern="[0-9]{1}"
        /><input
          type="text"
          maxLength="1"
          size="1"
          min="0"
          max="9"
          pattern="[0-9]{1}"
        />
      </div> -->
      <div class="code-container">
        <CodeInput
          :fieldWidth="42"
          :fields="4"
          v-on:complete="onComplete"
          required
        />
      </div>

      <p>
        
        {{ $t("Didn't recive the confirmation code?") }}
        <router-link to="/SignIn" class="blue2">
        {{ $t("Resend") }}
        </router-link>
      </p>
      <button class="input blue2-back white" type="submit">{{ $t("Next") }}</button>
    </form>
    <!-- <div class="sign forgot-password"></div> -->
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  import CodeInput from "vue-verification-code-input";

  export default {
    name: "ConfirmationCode",
    components: { CodeInput },
    data() {
      return {
        code: null,
      };
    },
    methods: {
      ...mapActions(["checkCode"]),
      onComplete(v) {
        this.code = v;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/sign.scss";
</style>
