<template>
  <div>
    <Loader v-if="!$store.state.auth.MyCourses"></Loader>
    <div v-else class="padding2">
      <div class="splitter">
        <div class="inner"></div>
      </div>
      <h2 class="title">{{ $t("My courses") }}</h2>
      <div class="flex wrap">
        <SingleTraining
          v-for="course in $store.state.auth.MyCourses.slice(0, limit)"
          :key="course.id"
          :course="course.course"
        ></SingleTraining>
      </div>
      <div
        class="flex center"
        v-if="$store.state.auth.MyCourses.length > limit"
      >
        <button
          class="input getmore-button orange3-back white"
          @click="getmore()"
        >
          {{ $t("Get more courses") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import Loader from "../components/Loader";
  import SingleTraining from "./SingleTraining.vue";
  import { mapActions } from "vuex";

  export default {
    name: "MyCourses",
    components: { Loader, SingleTraining },
    data() {
      return {
        limit: 4,
      };
    },
    methods: {
      ...mapActions(["getMyCourses"]),
      getmore() {
        this.limit += 4;
      },
    },
    mounted() {
      this.getMyCourses();
    },
  };
</script>

<style lang="sass" scoped>
  @import "../styles/globalsVars.scss"
</style>
