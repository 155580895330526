<template>
  <router-link
    :to="{ name: 'course', params: { id: course.id } }"
    class="train-container animate__animated animate__pulse"
  >
    <div class="img-container flex"><img :src="course.photo" alt="" /></div>
    <div class="training">
      <div class="details-container">
        <div class="flex space-btw">
          <h3>{{ course.name }}</h3>
          <p class="green-border">{{ course.status || course.address }}</p>
        </div>
        <p>{{ course.trainer }}</p>
        <div class="flex space-btw">
          <span class="blue">{{ course.type }}</span>
          <span class="yellow">
            <Rate :rate="course.rate"></Rate>
          </span>
        </div>
      </div>
    </div>
    <div class="time-details white">
      <p>{{ course.date }} {{ course.time }}</p>
    </div>
  </router-link>
</template>

<script>
import Rate from "./Rate";
export default {
  name: "SingleTraining",
  components: { Rate },
  props: ["course"],
};
</script>

<style lang="sass" scoped>
@import "../styles/globalsVars.scss"
@import "../styles/SingleTraining.scss"
</style>
