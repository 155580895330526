import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import stores from "./modules/stores";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { auth, stores },
});
