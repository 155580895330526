<template>
  <header>
    <nav>
      <div class="flex space-btw nav">
        <h1 class="flex center">
          <router-link to="/">
            <img
              :src="
                this.$i18n.locale == 'en'
                  ? require('@/assets/logo/full-logo-en.png')
                  : require('@/assets/logo/full-logo.png')
              "
              alt="logo"
            />
          </router-link>
        </h1>
        <div class="flex space-btw left">
          <div v-if="isLoggedIn" class="cart-not flex align">
            <div @click="showProfile()" class="profile-button modal-opener">
              <p class="modal-opener">{{ $t("Welcome ...") }}</p>
              <p class="modal-opener">{{ this.$store.state.auth.userName }}</p>
            </div>
            <img
              id="cart-icon"
              src="../assets/cart.svg"
              alt=""
              @click="showCart()"
              class="icon modal-opener"
            />
            <!-- <img
              id="notification-icon"
              src="../assets/bell.svg"
              alt=""
              @click="showNotification()"
              class="icon modal-opener"
            /> -->
            <NotificationDrop
              v-if="notificationShow"
              v-click-outside-app="showNotification"
              @close="showNotification"
            ></NotificationDrop>
            <CartDrop
              v-if="cartShow"
              v-click-outside-app="showCart"
              @close="showCart"
            ></CartDrop>
            <ProfileDrop
              v-if="profileShow"
              v-click-outside-app="showProfile"
              @close="showProfile"
            ></ProfileDrop>
          </div>
          <div v-else class="flex align signup-in">
            <router-link to="/SignUp" class="orange2">{{
              $t("Sign Up")
            }}</router-link>
            <span> | </span>
            <router-link to="/SignIn">{{ $t("Log In") }}</router-link>
          </div>
          <div class="flex align">
            <button class="flex align lang" @click="changeLang">
              {{ this.$i18n.locale == "ar" ? "English" : "العربية" }}
              <img src="../assets/Group 77.png" class="icon" alt="" />
            </button>
          </div>
        </div>
      </div>
      <div class="flex navlist">
        <ul class="flex">
          <li>
            <router-link to="/">{{ $t("Home") }}</router-link>
            <div></div>
          </li>
          <li>
            <router-link to="/Offers">{{ $t("Offers") }}</router-link>
            <div></div>
          </li>
          <li>
            <router-link to="/Shops">{{ $t("Stores") }}</router-link>
            <div></div>
          </li>
          <li>
            <router-link to="/Trainings">{{ $t("Courses") }}</router-link>
            <div></div>
          </li>
        </ul>
        <!-- <SearchBar></SearchBar> -->
      </div>
    </nav>
    <Loader v-if="reload"></Loader>
  </header>
</template>

<script>
  // import SearchBar from "./SearchBar";
  import NotificationDrop from "./NotificationDrop";
  import CartDrop from "./CartDrop";
  import ProfileDrop from "./ProfileDrop";
  import { mapGetters } from "vuex";
  import Loader from "../components/Loader";

  export default {
    name: "Header",
    components: { NotificationDrop, CartDrop, ProfileDrop,Loader },
    data() {
      return {
        notificationShow: false,
        cartShow: false,
        profileShow: false,
        reload: false,
      };
    },
    methods: {
      changeLang() {
        this.reload = true;
        if (this.$i18n.locale == "en") {
          this.$i18n.locale = "ar";
          window.localStorage.setItem("lang", "ar");
          document.querySelector("html").setAttribute("lang", "ar");
        } else {
          this.$i18n.locale = "en";
          window.localStorage.setItem("lang", "en");
          document.querySelector("html").setAttribute("lang", "en");
        }
        location.reload();
      },
      showCart() {
        this.cartShow = !this.cartShow;
        this.notificationShow = false;
        this.profileShow = false;
      },
      showNotification() {
        this.notificationShow = !this.notificationShow;
        this.cartShow = false;
        this.profileShow = false;
      },
      showProfile() {
        this.profileShow = !this.profileShow;
        this.cartShow = false;
        this.notificationShow = false;
      },
    },
    computed: mapGetters(["isLoggedIn"]),
  };
</script>

<style lang="scss">
  @import "../styles/globalsVars.scss";
  @import "../styles/header.scss";
</style>
