<template>
  <div class="finish to-right">
    <h2>
      {{ $t("Address") }}
    </h2>
    <p>
      {{ $t("Docura fast food") }}
    </p>
    <div>
      {{ this.$store.state.auth.selectedAddress.name }}
      <p>{{ this.$store.state.auth.selectedAddress.description }}</p>
    </div>
    <div class="splitter">
      <div class="inner"></div>
    </div>
    <h2>
      {{ $t("Delivery company") }}
    </h2>
    <div class="flex container">
      <div>
        <div class="img-container flex align center">
          <img :src="this.$store.state.auth.selectedDelivery.photo" alt="" />
        </div>
        <div class="flex col center">
          <h3>
            {{ $t("Company") }}
            {{ this.$store.state.auth.selectedDelivery.name }}
          </h3>
          <p>
            {{ $t("Establishment") }}
            {{ this.$store.state.auth.selectedDelivery.name }}
          </p>
        </div>
      </div>
      <router-link to="/Delivery">
        {{ $t("Change") }}
      </router-link>
    </div>

    <div class="splitter">
      <div class="inner"></div>
    </div>
    <h2>
      {{ $t("Payment method") }}
    </h2>
    <div class="flex align container">
      <div>
        <img
          class="icon"
          :src="this.$store.state.auth.selectedMethod.photo"
          alt=""
        />
        {{ this.$store.state.auth.selectedMethod.description }}
      </div>
      <router-link to="/Payment">
        {{ $t("Change") }}
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "FinishOrder",
    components: {},
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/addresses.scss";
</style>
