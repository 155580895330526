<template>
  <footer>
    <div class="flex space-btw wrap align blue-back">
      <ul class="flex wrap white">
        <li>
          <router-link to="/WhoAreWe">{{ $t("Who are we") }}</router-link>
        </li>
        <li>
          <router-link to="/TermsCondition">{{
            $t("Terms & Conditions")
          }}</router-link>
        </li>
        <li>
          <router-link to="/ContactUs">{{ $t("Contact Us") }}</router-link>
        </li>
      </ul>
      <ul class="flex wrap social">
        <li>
          <a :href="this.$store.state.auth.settings.instagram" target="blank"
            ><img src="../assets/ig.svg" alt=""
          /></a>
        </li>
        <li>
          <a href="https://www.youtube.com/"
            ><img src="../assets/youtube.svg" alt=""
          /></a>
        </li>
        <li>
          <a :href="this.$store.state.auth.settings.twitter" target="blank"
            ><img src="../assets/twitter.svg" alt=""
          /></a>
        </li>
        <li>
          <a :href="this.$store.state.auth.settings.facebook" target="blank"
            ><img src="../assets/facebook.svg" alt=""
          /></a>
        </li>
      </ul>
    </div>
    <div class="orange-back">
      <div class="flex space-btw wrap align data">
        <ul class="flex connections wrap">
          <li>
            <div class="flex">
              <img src="../assets/phone.svg" alt="" />
              <div>
                <p>{{ $t("Phone") }}</p>
                <p class="white">
                  {{ this.$store.state.auth.settings.telephone }}
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex">
              <img src="../assets/mobile.svg" alt="" />
              <div>
                <p>{{ $t("Mobile") }}</p>
                <p class="white">01234567899</p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex">
              <img src="../assets/mail.svg" alt="" />
              <div>
                <p>{{ $t("Email") }}</p>
                <p class="white">
                  {{ this.$store.state.auth.settings.email }}
                </p>
              </div>
            </div>
          </li>
          <li>
            <div class="flex">
              <img src="../assets/laptop.svg" alt="" />
              <div>
                <p>{{ $t("Introductory site") }}</p>
                <p class="white">DocuraLandingPage.com</p>
              </div>
            </div>
          </li>
        </ul>
        <ul class="flex wrap apps">
          <li>
            <a :href="this.$store.state.auth.settings.appstore" target="blank"
              ><img src="../assets/appstore_dark.png" alt=""
            /></a>
          </li>
          <li>
            <a :href="this.$store.state.auth.settings.playstore" target="blank"
              ><img src="../assets/googlestore_dark.png" alt=""
            /></a>
          </li>
        </ul>
      </div>
      <p class="copyrights white">{{ $t("All rights reserved to Dosura") }}</p>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "Footer",
  };
</script>
<style lang="sass" scoped>
  @import "../styles/globalsVars.scss"
  @import "../styles/footer.scss"
</style>
