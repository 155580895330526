import axios from "axios";
import router from "../../router/index";
import i18n from "../../i18n";

const state = {
  rootURL: "https://docura.sa/api/",
  // ******************** user data ********************
  token:
    window.sessionStorage.getItem("token") ||
    window.localStorage.getItem("token"),
  keepme: false,
  userName: window.localStorage.getItem("userName"),
  lastName: window.localStorage.getItem("lastName"),
  profiledata: null,
  errorToSign:false,

  // ******************** cart data ********************
  store_id: window.localStorage.getItem("cart_store"),
  cartProducts: null,
  selectedAddress: null,
  selectedDelivery: null,
  selectedMethod: null,
  selectedCityID: null,
  totalprice: 0,

  // ********************  ********************

  settings: null,
  delivery: 0,
  deliveryDiscount: 0,
  mail: "",
  code: "",
  error: false,
  msg: null,
  registeredAcc: false,
  resetPassword: false,

  // ******************** stores ********************

  storesbyRate: null,

  // for getting stores by category
  categories: null,
  activeCategory: null,
  shopsbyCategory: null,
  nextpageInshops: null,

  // fav stores
  favStores: null,
  nextpageInfavStores: null,

  // ******************** offers ********************

  offers: null,
  nextpageInOffers: null,

  // ******************** courses ********************
  courses: null,
  nextpageInCourses: null,
  coursesbyRate: null,

  // for getting stores by trainer

  trainers: null,
  activeTrainer: null,
  coursesbyTrainer: null,

  // fav stores
  MyCourses: null,

  orders: null,

  subloader: false,

  // coupons
  coupons: [],
  applied_Coupon_Discount: 0,
  cities: [],
};

const getters = {
  isLoggedIn: (state) => state.token,
  totalProducts: (state) =>
    state.totalprice * ((100 - state.applied_Coupon_Discount) / 100),
  tax: (state) =>
    (state.totalprice *
      ((100 - state.applied_Coupon_Discount) / 100) *
      state.settings.tax) /
    100,
  finalPrice: (state) =>
    (
      state.totalprice * ((100 - state.applied_Coupon_Discount) / 100) +
      parseFloat(state.delivery) *
        ((100 - parseFloat(state.deliveryDiscount)) / 100)
    ).toFixed(2),
};

const actions = {
  signup: ({ commit }, register) => {
    // Simple POST request with a JSON body using axios
    axios.post(`${state.rootURL}auth/register`, register).then((response) => {
      if (response.data.status) {
        commit("setToken", response.data.data.token);

        window.localStorage.setItem("token", response.data.data.token);
        commit("setData", response.data.data);
        commit("tooglregisteredAcc");
      } else {
        commit("tooglErorr");
        commit("serMsg", response.data.msg);
      }
    });
  },
  finalizeSignup: ({ commit }) => {
    commit("tooglregisteredAcc");
    router.push("/");
  },
  login: ({ commit }, data) => {
    // Simple POST request with a JSON body using axios
    axios.post(`${state.rootURL}auth/login`, data).then((response) => {
      if (response.data.status) {
        commit("setToken", response.data.data.token);
        window.localStorage.setItem("token", response.data.data.token);
        commit("setData", response.data.data);
        window.localStorage.setItem("kept", state.keepme);
        router.push("/");
      } else {
        commit("tooglErorr");
        commit("serMsg", response.data.msg);
      }
    });
  },
  logout: ({ commit }) => {
    commit("setToken", null);
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("kept");
  },
  checkMail: ({ commit }, data) => {
    // Simple POST request with a JSON body using axios
    axios.post(`${state.rootURL}auth/email_check`, data).then((response) => {
      if (response.data.status) {
        router.push("/ConfirmationCode");
        commit("setMail", data.email);
      } else {
        commit("tooglErorr");
        commit("serMsg", response.data.msg);
      }
    });
  },
  checkCode: ({ commit }, data) => {
    // Simple POST request with a JSON body using axios
    axios.post(`${state.rootURL}auth/code_check`, data).then((response) => {
      if (response.data.status) {
        router.push("/ChangePassword");
        commit("setCode", data.code);
      } else {
        commit("tooglErorr");
        commit("serMsg", response.data.msg);
      }
    });
  },
  resetPassword: ({ commit }, data) => {
    data.email = state.mail;
    data.code = state.code;
    // Simple POST request with a JSON body using axios
    axios.post(`${state.rootURL}auth/reset_password`, data).then((response) => {
      if (response.data.status) {
        commit("setToken", response.data.data.token);
        window.localStorage.setItem("token", response.data.data.token);
        commit("setData", response.data.data);
        state.resetPassword = true;
      } else {
        commit("tooglErorr");
        commit("serMsg", response.data.msg);
      }
    });
  },
  finalizeResettingPass: () => {
    router.push("/");
  },
  operError:({commit}, msg) =>{
    commit("serMsg", msg);
    commit("tooglErorr");
  },
  closeError: ({ commit }) => {
    commit("tooglErorr");
    commit("toogleErrorToSign", false);
  },

  // /////////////////////////////////////////////////////// like
  toogleFav: (commit, data) => {
    axios.post(`${state.rootURL}favourite`, data, {
      headers: { Authorization: `Bearer ${state.token}` },
    });
  },
  // /////////////////////////////////////////////////////// subscribe courses
  toogleSubscribe: (commit, data) => {
    axios
      .post(`${state.rootURL}mycource`, data, {
        headers: { Authorization: `Bearer ${state.token}` },
      })
      .then(() => {
        // console.log(response);
      });
  },
  getCourses: () => {
    //****************************** getting courses ******************************
    axios
      .get(`${state.rootURL}courses`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
          "x-localization": i18n.locale,
        },
      })
      .then((response) => {
        state.courses = response.data.data.items;
        state.nextpageInCourses = response.data.data.paginate.next_page_url;
      });
  },
  getCoursesByRate: () => {
    //****************************** getting courses by rate ******************************
    axios
      .get(`${state.rootURL}courses_rate`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
          "x-localization": i18n.locale,
        },
      })
      .then((response) => {
        state.coursesbyRate = response.data.data.items;
      });
  },
  getStoresByRate: () => {
    //****************************** getting stores by rate ******************************
    axios
      .get(`${state.rootURL}stores`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
          "x-localization": i18n.locale,
        },
      })
      .then((response) => {
        state.storesbyRate = response.data.data.items;
      });
  },

  getFavShops: () => {
    if (state.token) {
      //****************************** getting fav shops ******************************
      // Simple GET request using axios
      axios
        .get(`${state.rootURL}favourite`, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((response) => {
          state.favStores = response.data.data;
          // state.nextpageInfavStores = response.data.data.paginate.next_page_url;
        });
    }
  },
  getMyCourses: () => {
    if (state.token) {
      //****************************** getting my courses ******************************
      // Simple GET request using axios
      axios
        .get(`${state.rootURL}mycource`, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((response) => {
          state.MyCourses = response.data.data;
        });
    }
  },

  getCategories: () => {
    state.shopsbyCategory = null;
    //****************************** getting categories ******************************
    axios.get(`${state.rootURL}categories`).then((response) => {
      state.categories = response.data.data;
      state.activeCategory = response.data.data[0].id;
      actions.getshopsbyCategory();
    });
  },
  getshopsbyCategory: (commit, id) => {
    if (id) {
      state.activeCategory = id;
    }
    //****************************** getting shop data ******************************
    axios
      .get(`${state.rootURL}category_store/${state.activeCategory}`, {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
      .then((response) => {
        state.shopsbyCategory = response.data.data.items;
        state.nextpageInshops = response.data.data.paginate.next_page_url;
        state.subloader = false;
      });
  },
  getTrainers: () => {
    //****************************** getting trainers ******************************
    axios.get(`${state.rootURL}trainers`).then((response) => {
      state.trainers = response.data.data;
      state.activeTrainer = response.data.data[0];
      actions.getCoursesbyTrainer();
    });
  },
  getCoursesbyTrainer: (commit, trainer) => {
    state.coursesbyTrainer = null;
    if (trainer) {
      state.activeTrainer = trainer;
    }
    //****************************** getting shop data ******************************
    axios
      .get(`${state.rootURL}trainers/${state.activeTrainer.id}`)
      .then((response) => {
        state.coursesbyTrainer = response.data.data;
      });
  },
  getProfileData: () => {
    if (state.token) {
      //****************************** getting profile ******************************

      // Simple GET request using axios
      axios
        .get(`${state.rootURL}auth/profile`, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
        .then((response) => {
          state.profiledata = response.data.data;
        });
    }
  },
  getOrders: (commit, data) => {
    state.orders = null;
    // Simple POST request with a JSON body using axios
    axios
      .post(
        `${state.rootURL}order`,
        { status: data },
        {
          headers: { Authorization: `Bearer ${state.token}` },
        }
      )
      .then((response) => {
        state.orders = response.data.data.items;
      });
  },
  getSettings: () => {
    //****************************** getting settings ******************************
    // Simple GET request using axios
    axios.get(`${state.rootURL}settings`).then((response) => {
      state.settings = response.data.data;
      // console.log(state.settings)
    });
  },

};

const mutations = {
  setToken: (state, token) => {
    state.token = token;
  },
  setData: (state, data) => {
    state.userName = data.name;
    window.localStorage.setItem("userName", data.name);
    state.lastName = data.lastname;
    window.localStorage.setItem("lastName", data.lastname);
  },
  setMail: (state, data) => {
    state.mail = data;
  },
  setCode: (state, data) => {
    state.code = data;
  },
  serMsg: (state, msg) => {
    state.msg = msg;
  },
  toogleErrorToSign: (state, check) => {
    state.errorToSign = check;
  },
  tooglErorr: (state) => {
    state.error = !state.error;
  },
  tooglregisteredAcc: (state) => {
    state.registeredAcc = !state.registeredAcc;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
