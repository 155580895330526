<template>
  <div>
    <Loader v-if="!store.name"></Loader>
    <div v-else>
      <div class="overlay-container">
        <img :src="store.banner" alt="" />
        <button
          v-if="this.$store.state.auth.token"
          @click="
            toogleFav({ store_id: store.id });
            store.like = !store.like;
          "
        >
          {{
            store.like ? $t("Remove from favorites") : $t("Add to favorites")
          }}
        </button>
      </div>
      <section class="shop padding2">
        <div class="flex space-btw">
          <div>
            <h2 class="flex">
              {{ store.name }}

              <span class="yellow">
                <Rate :rate="store.rate"></Rate>
              </span>
            </h2>
            <p>{{ store.category }}</p>
            <div class="splitter">
              <div class="inner"></div>
            </div>
          </div>
          <div style="width: 30%">
            <a href="#comments" class="flex comments-link">
              <img src="../assets/conversation.png" alt="" />
              <span>
                {{ $t("Reviews") }}
              </span>
            </a>
          </div>
        </div>
        <div class="flex">
          <div class="right">
            <h3>
              {{ $t("Product list") }}
            </h3>
            <p>{{ store.category }}</p>
            <div class="flex wrap space-btw">
              <div
                v-for="product in store.products"
                :key="product.id"
                class="product flex align"
              >
                <div class="product-datails">
                  <h5>{{ product.name }}</h5>
                  <p>{{ product.description }}</p>
                  <div class="flex align space-btw">
                    <p class="blue">
                      {{ product.price }}
                      {{ $t("SR") }}
                    </p>
                    <img
                      class="modal-opener"
                      src="../assets/cart.svg"
                      alt=""
                      @click="
                        if (product.stocks) {
                          checkTheCart(product);
                        }
                      "
                    />
                  </div>
                  <p v-if="!product.stocks" style="color: red; font-size: 16px">
                    {{ $t("Out of stock") }}
                  </p>
                </div>
                <img :src="product.photo" alt="" class="product-img" />
              </div>
            </div>
          </div>
          <!-- <div v-if="this.$store.state.auth.cartProducts[0]" class="cart-section flex col">
            <CartSummary
              
            ></CartSummary>
          </div> -->
        </div>
      </section>
      <section class="padding comments" id="comments">
        <h2>
          {{ $t("Reviews") }}
        </h2>
        <p>
          {{ $t("Our customer's reviews") }}
        </p>

        <div>
          <div></div>
          <div></div>
        </div>
        <div
          v-for="review in store.reviews"
          :key="review.id"
          class="comment flex align animate__animated animate__bounceInLeft"
        >
          <div class="img-name-container flex col align">
            <div class="img-container flex align center">
              <img src="../assets/logo/logo.svg" alt="" />
            </div>
            <span>{{ review.username }}</span>
          </div>
          <div class="text-container">
            <div class="details flex space-btw">
              <span class="yellow"> <Rate :rate="review.rate"></Rate> </span>
              <span> {{ review.created_at }} </span>
            </div>
            <p>
              {{ review.review }}
            </p>
          </div>
        </div>
      </section>
      <div v-if="addToCartModal" v-click-outside-app="toogleAddtoCart">
        <transition name="model">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal">
                <img src="../assets/talabaty.svg" alt="" />
                <h3 class="blue">
                  {{ $t("Please choose quantity") }}
                </h3>
                <form @submit.prevent="addtocart">
                  <div class="flex align center">
                    <button type="button" class="add-minus" @click="increment">
                      +
                    </button>
                    <input
                      type="number"
                      name=""
                      id=""
                      min="1"
                      :value="quantity"
                    />
                    <button type="button" class="add-minus" @click="decrement">
                      -
                    </button>
                  </div>
                  <button type="submit" class="input blue2-back white">
                    {{ $t("Add to cart") }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  // import CartSummary from "./CartSummary";
  import Rate from "./Rate";
  import Loader from "./Loader";
  import { mapActions } from "vuex";
  import axios from "axios";

  export default {
    name: "ShopPage",
    components: { Rate, Loader },
    data() {
      return {
        store: {},
        addToCartModal: false,
        quantity: 1,
        selectedProduct: null,
      };
    },
    methods: {
      ...mapActions(["toogleFav", "operError"]),
      getstore() {
        //****************************** getting shop data ******************************
        axios
          .get(
            `https://docura.sa/api/store/${this.$route.params.id}`,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
                "x-localization": this.$i18n.locale,
              },
            }
          )

          .then((response) => {
            this.store = response.data.data;
          });
      },
      toogleAddtoCart() {
        this.addToCartModal = !this.addToCartModal;
      },
      checkTheCart(product) {
        if (this.$store.state.auth.token) {
          if (this.$store.state.auth.store_id) {
            if (this.$route.params.id != this.$store.state.auth.store_id) {
              let newOrder = confirm(
                "يوجد في السلة طلب من متجر اخر. هل تريد اضافة طلب جديد بدلا منه ؟ "
              );
              if (newOrder) {
                this.$store.state.auth.cartProducts = [];
                this.$store.state.auth.totalprice = 0;
                this.$store.state.auth.store_id = this.$route.params.id;
                window.localStorage.setItem(
                  "cart_store",
                  this.$route.params.id
                );
                this.setProduct(product);
                this.toogleAddtoCart();
              }
            } else {
              this.setProduct(product);
              this.toogleAddtoCart();
            }
          } else {
            this.$store.state.auth.store_id = this.$route.params.id;
            window.localStorage.setItem("cart_store", this.$route.params.id);
            this.setProduct(product);
            this.toogleAddtoCart();
          }
        } else {
          this.$store.state.auth.errorToSign = true;
          this.operError("فضلاً قم بتسجيل الدخول لاتمام الطلب");
        }
      },
      setProduct(product) {
        this.quantity = 1;
        this.$store.state.auth.cartProducts.forEach((item) => {
          if (item.product.id == product.id) {
            this.quantity = item.qty;
            this.$store.state.auth.totalprice -= product.price * item.qty;
            this.$store.state.auth.cartProducts.splice(
              this.$store.state.auth.cartProducts.indexOf(item),
              1
            );
          }
        });

        this.selectedProduct = product;
      },
      addtocart() {
        this.$store.state.auth.cartProducts.push({
          product: this.selectedProduct,
          qty: this.quantity,
        });
        let cart = JSON.stringify(this.$store.state.auth.cartProducts);
        window.localStorage.setItem("cart", cart);
        this.$store.state.auth.totalprice +=
          this.selectedProduct.price * this.quantity;

        this.toogleAddtoCart();
      },
      increment() {
        this.quantity++;
      },
      decrement() {
        if (this.quantity > 1) this.quantity--;
      },
    },

    mounted() {
      this.getstore();
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/shopPage.scss";
</style>
