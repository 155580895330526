<template>
  <div class="sign-up-color">
    <div class="flex container center">
      <form class="flex col">
        <h1>
          <router-link to="/">
            <img src="../assets/logo/full-logo.png" alt="logo" />
          </router-link>
        </h1>
        <p>
          {{ $t("We are happy to sign you up to get our services") }}
        </p>
        <button type="button" class="input red-back white">
          {{ $t("Sign Up with Google") }}
        </button>
        <button
          type="button"
          class="input fc-blue-back white"
          @click.prevent="logInWithFacebook"
        >
          {{ $t("Sign Up with Facebook") }}
        </button>

        <span> {{ $t("Or") }} </span>
        <button type="button" class="input blue2-back">
          <router-link to="/SignUpWMail" class="white">
            {{ $t("Sign Up with mail") }}
          </router-link>
        </button>
        <div class="flex space-btw">
          <p>
            {{ $t("Already have an account?") }}

            <router-link to="/SignIn" class="orange2">
              {{ $t("Log In") }}
            </router-link>
          </p>
          <p>
            <router-link to="/" class="orange2">
              {{ $t("Skip") }}
            </router-link>
          </p>
        </div>
      </form>
      <!-- <div class="sign sign-up"></div> -->
    </div>
  </div>
</template>

<script>
  // import facebookLogin from "facebook-login-vuejs";
  // import { initFbsdk } from "@/config/fb.js";

  export default {
    name: "SignUP",
    components: {},
    data() {
      return {
        isConnected: false,
        name: "",
        email: "",
        personalID: "",
        FB: undefined,
      };
    },
    methods: {
      async logInWithFacebook() {
        await this.loadFacebookSDK(document, "script", "facebook-jssdk");
        await this.initFacebook();
        console.log(window.FB);
        window.FB.login(function (response) {
          if (response.authResponse) {
            alert("You are logged in &amp; cookie set!");
            console.log(response);
            // Now you can redirect the user or do an AJAX request to
            // a PHP script that grabs the signed request from the cookie.
          } else {
            alert("User cancelled login or did not fully authorize.");
          }
        });
        return false;
      },
      async initFacebook() {
        window.fbAsyncInit = function () {
          window.FB.init({
            appId: "855647948635725", //You will need to change this
            cookie: true, // This is important, it's not enabled by default
            xfbml: true,
            version: "v13.0",
          });
        };
      },
      async loadFacebookSDK(d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/sign.scss";
</style>
