<template>
  <div>
    <Loader v-if="!companies"></Loader>
    <div v-else>
      <h2>
        {{ $t("Delivery's Companies") }}
      </h2>
      <p>
        {{ $t("Docura fast food") }}
      </p>
      <div class="to-right">
        <label
          class="container flex"
          v-for="company in companies"
          :key="company.id"
        >
          <div class="flex">
            <div class="img-container flex align center">
              <img :src="company.photo" alt="" />
            </div>
            <div class="flex col center">
              <h3>{{ $t("Company") }} {{ company.name }}</h3>
              <p>{{ $t("Establishment") }} {{ company.name }}</p>
            </div>
          </div>
          <input
            type="radio"
            name="shipping-co"
            v-model="selectedDelivery"
            @change="Selectcompany"
            :value="company"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Loader from "./Loader.vue";
  import { mapGetters } from "vuex";

  export default {
    name: "Delivery",
    components: { Loader },
    data() {
      return {
        companies: null,
        selectedDelivery: null,
      };
    },
    computed: mapGetters(["totalProducts"]),

    methods: {
      Selectcompany() {
        this.$store.state.auth.selectedDelivery = this.selectedDelivery;
        let city = this.selectedDelivery.cities.find(
          (e) => e.id == this.$store.state.auth.selectedAddress.city_id
        );
        this.$store.state.auth.delivery = city.price;
        let offer = this.selectedDelivery.offers.find(
          (e) =>
            e.amount <= this.totalProducts && e.amount_to >= this.totalProducts
        );
        if (!offer) {
          offer = { discount: 0 };
        }
        this.$store.state.auth.deliveryDiscount = offer.discount;
      },
    },
    mounted() {
      // this.$store.state.auth.selectedDelivery = null;
      //****************************** getting delivery companies ******************************
      axios
        .get(`${this.$store.state.auth.rootURL}compaiens`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.auth.token}`,
          },
        })
        .then((response) => {
          this.companies = response.data.data;
        });
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/addresses.scss";
</style>
