<template>
  <div>
    <div class="splitter">
      <div class="inner"></div>
    </div>
    <h2 class="title padding2">{{ $t("Notifications") }}</h2>
    <div class="notification-container">
      <div
        v-for="i in 8"
        :key="i"
        :class="{ new: i < 3, old: i > 2 }"
        class="notification flex align space-btw"
      >
        <div class="flex">
          <div class="img-container flex center align">
            <img src="../assets/logo/logo.svg" alt="" />
          </div>
          <div class="flex col center">
            <p>
              {{ $t("The status of your order number") }}
              <span class="blue">21547</span>
              {{ $t("has been changed") }}
            </p>
            <span class="grey">1 jan 2020 , 2:00 PM</span>
          </div>
        </div>

        <a>close</a>
      </div>
    </div>
    <div class="flex center">
      <button class="input orange3-back white">
        {{ $t("Delete all notifications") }}
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Notification",
    components: {},
  };
</script>
<style lang="sass" scoped>
  @import "../styles/globalsVars.scss"
  @import "../styles/notifications.scss"
</style>
