<template>
  <div>
    <Loader v-if="!course.name"></Loader>
    <div class="overlay-container">
      <img :src="course.banner" alt="" />
      <div class="overlay">
        <div class="inner-overlay">
          <h1>الدورات <br /><span class="white">Courses</span></h1>
          <button
            v-if="this.$store.state.auth.token"
            @click="
              toogleSubscribe({ course_id: course.id });
              course.subscribe = !course.subscribe;
            "
          >
            {{ course.subscribe ? $t("Unsubscribe") : $t("Subscribe") }}
          </button>
        </div>
      </div>
    </div>
    <div class="padding2">
      <div class="flex align space-btw padding2 title-wraper">
        <h2>
          {{ course.name }} <br />
          <span class="yellow" style="font-size: 1.5rem">
            <Rate :rate="course.rate"></Rate>
          </span>
        </h2>
        <p v-if="course.type == 'paid'" style="font-size: 2rem">
          {{ course.price }} {{ $t("SR") }}
        </p>
      </div>

      <div class="trainer-section flex">
        <img :src="course.trainer_image" alt="" />
        <div class="flex col center padding2">
          <h3 class="orange">{{ course.trainer }}</h3>
          <p>
            {{ course.description }}
          </p>
        </div>
      </div>
      <div class="title-wraper">
        <h3>
          {{ $t("Syllabus") }}
        </h3>
        <p>
          {{ $t("Vedios from View Class") }}
        </p>
      </div>
      <div class="flex items-container wrap">
        <Lec
          v-for="lec in lessons.items"
          :key="lec.id"
          :lec="lec"
          :enabled="course.subscribe"
          :courseName="course.name"
        ></Lec>
      </div>
      <div class="flex center" v-if="course.lessons">
        <button
          class="input getmore-button orange3-back white"
          @click="getmore()"
        >
          {{ $t("Get more vedios") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import Loader from "../components/Loader";
  import Lec from "../components/Lec";
  import Rate from "../components/Rate";
  import axios from "axios";
  import { mapActions } from "vuex";

  export default {
    name: "Course",
    components: { Lec, Loader, Rate },
    data() {
      return {
        course: {},
        lessons: {},
      };
    },
    methods: {
      ...mapActions(["toogleSubscribe"]),
      getmore() {},
    },
    mounted() {
      //****************************** getting course data ******************************
      axios
        .get(
          `https://docura.sa/api/courses/${this.$route.params.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          }
        )
        .then((response) => {
          this.course = response.data.data.courses;
          this.lessons = response.data.data.lessons;
        });
    },
  };
</script>
<style lang="sass" scoped>
  @import "../styles/globalsVars.scss"
  @import "../styles/course.scss"
</style>
