<template>
  <div>
    <Loader v-if="!$store.state.auth.profiledata"></Loader>
    <div v-else>
      <div class="splitter">
        <div class="inner"></div>
      </div>
      <div class="flex space-btw padding">
        <form class="profile-data flex col align" @submit.prevent="clicked">
          <h2 class="title">
            {{ $t("Personal informations") }}
          </h2>
          <ul>
            <li>
              <h3>
                {{ $t("First name") }}
              </h3>
              <input
                type="text"
                :placeholder="this.$store.state.auth.profiledata.name"
                v-model="name"
                @keyup="changed"
              />
            </li>
            <li>
              <h3>
                {{ $t("Last name") }}
              </h3>
              <input
                type="text"
                :placeholder="this.$store.state.auth.profiledata.lastname"
                v-model="lastname"
                @keyup="changed"
              />
            </li>
            <li>
              <h3>
                {{ $t("Email") }}
              </h3>
              <input
                type="email"
                :placeholder="this.$store.state.auth.profiledata.email"
                v-model="email"
                @keyup="changed"
              />
            </li>
            <li>
              <h3>
                {{ $t("Mobile") }}
              </h3>
              <input
                type="text"
                :placeholder="this.$store.state.auth.profiledata.phone"
                v-model="phone"
                @keyup="changed"
              />
            </li>
          </ul>
          <button
            type="submit"
            class="input getmore-button orange-back white"
            :class="{
              disabled: !thereisChange,
            }"
            :disabled="!thereisChange"
          >
            {{ $t("Save") }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import axios from "axios";
  import Loader from "../components/Loader";
  export default {
    name: "EditProfile",
    components: { Loader },
    data() {
      return {
        thereisChange: false,
        name: "",
        lastname: "",
        email: "",
        phone: "",
        data: {},
      };
    },
    methods: {
      ...mapActions(["getProfileData"]),
      clicked() {
        this.data = {};
        if (
          !(
            this.name.toLowerCase().toLowerCase() ==
              this.$store.state.auth.profiledata.name.toLowerCase() ||
            this.name == ""
          )
        ) {
          this.data.name = this.name;
        }
        if (
          !(
            this.lastname.toLowerCase().toLowerCase() ==
              this.$store.state.auth.profiledata.lastname.toLowerCase() ||
            this.lastname == ""
          )
        ) {
          this.data.lastname = this.lastname;
        }
        if (
          !(
            this.email.toLowerCase().toLowerCase() ==
              this.$store.state.auth.profiledata.email.toLowerCase() ||
            this.email == ""
          )
        ) {
          this.data.email = this.email;
        }
        if (
          !(
            this.phone.toLowerCase().toLowerCase() ==
              this.$store.state.auth.profiledata.phone.toLowerCase() ||
            this.phone == ""
          )
        ) {
          this.data.phone = this.phone;
        }
        axios
          .post(
            `${this.$store.state.auth.rootURL}auth/profile/edit`,
            this.data,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.auth.token}`,
              },
            }
          )
          .then((response) => {
            window.localStorage.setItem("userName", response.data.data.name);
            this.$store.state.auth.userName = response.data.data.name;
          });
        this.getProfileData();
      },
      changed() {
        if (
          (this.name.toLowerCase().toLowerCase() ==
            this.$store.state.auth.profiledata.name.toLowerCase() ||
            this.name == "") &&
          (this.lastname.toLowerCase() ==
            this.$store.state.auth.profiledata.lastname.toLowerCase() ||
            this.lastname == "") &&
          (this.email.toLowerCase() ==
            this.$store.state.auth.profiledata.email.toLowerCase() ||
            this.email == "") &&
          (this.phone.toLowerCase() ==
            this.$store.state.auth.profiledata.phone.toLowerCase() ||
            this.phone == "")
        ) {
          this.thereisChange = false;
        } else {
          this.thereisChange = true;
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import "../styles/globalsVars.scss"
  @import "../styles/profile.scss"
</style>
