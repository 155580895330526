<template>
  <div class="notifications">
    <h3 class="flex align center">
      <img src="../assets/bell.svg" alt="" />
      الإشعارات الجديدة
    </h3>
    <div v-for="i in 4" :key="i" class="notification">
      <p>
        تم تغيير حالة الطلب الخاص بك رقم
        <span class="blue">21547</span>
      </p>
      <span class="grey">1 jan 2020 , 2:00 PM</span>
    </div>
    <router-link
      to="/Notifications"
      class="blue flex center"
      @click.native="close"
      >عرض جميع الاشعارات</router-link
    >
  </div>
</template>

<script>
export default {
  name: "NotificationDrop",
  components: {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
