<template>
  <div>
    <Loader v-if="!$store.state.auth.favStores"></Loader>
    <div v-else class="padding2">
      <div class="splitter">
        <div class="inner"></div>
      </div>
      <h2 class="title">
        {{ $t("Favorite stores list") }}
      </h2>
      <div class="flex wrap">
        <SingleShop
          v-for="shop in $store.state.auth.favStores"
          :key="shop.id"
          :shop="shop.store"
        ></SingleShop>
      </div>
      <div class="flex center" v-if="$store.state.auth.favStores">
        <button
          class="input getmore-button orange3-back white"
          @click="getmore()"
        >
          {{ $t("Get more stores") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import Loader from "../components/Loader";
  import SingleShop from "../components/SingleShop";
  import { mapActions } from "vuex";

  export default {
    name: "FavShops",
    components: { Loader, SingleShop },
    data() {
      return {};
    },
    methods: {
      ...mapActions(["getFavShops"]),
      getmore() {},
    },
    mounted() {
      this.getFavShops();
    },
  };
</script>

<style lang="sass" scoped>
  @import "../styles/globalsVars.scss"
</style>
