<template>
  <div class="flex padding2 layout">
    <div class="content">
      <div class="splitter">
        <div class="inner"></div>
      </div>
      <router-view />
    </div>
    <div class="cart-section">
      <CartSummary></CartSummary>
    </div>
  </div>
</template>

<script>
import CartSummary from "../components/CartSummary";
export default {
  name: "CartLayout",
  components: { CartSummary },
};
</script>

<style lang="scss" scoped>
@import "../styles/globalsVars.scss";
@import "../styles/cartLayout.scss";
</style>
