import { render, staticRenderFns } from "./Shops.vue?vue&type=template&id=2e6d04b7&scoped=true"
import script from "./Shops.vue?vue&type=script&lang=js"
export * from "./Shops.vue?vue&type=script&lang=js"
import style0 from "./Shops.vue?vue&type=style&index=0&id=2e6d04b7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../.yarn/__virtual__/vue-loader-virtual-723514ec82/2/.yarn/berry/cache/vue-loader-npm-15.11.1-829412283a-10c0.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e6d04b7",
  null
  
)

export default component.exports