<template>
  <div class="flex container center">
    <form
      class="flex col"
      @submit.prevent="
        login({
          email: email,
          password: password,
        })
      "
    >
      <h1>
        <router-link to="/">
          <img src="../assets/logo/full-logo.png" alt="logo" />
        </router-link>
      </h1>
      <h2 class="blue">
        {{ $t("Log In") }}
      </h2>
      <input
        class="input"
        type="email"
        name="email"
        :placeholder="$t('Email')"
        v-model="email"
        required
      />
      <input
        class="input"
        type="password"
        name=""
        :placeholder="$t('Password')"
        required
        v-model="password"
      />
      <div class="flex space-btw">
        <div>
          <input
            type="checkbox"
            name="rememberme"
            id=""
            @change="keepme($event)"
          />
          <label for="rememberme">تذكرني</label>
        </div>
        <router-link to="/ForgotPassword" class="orange2">
          {{ $t("Forgot Password?") }}
        </router-link>
      </div>
      <button class="input blue2-back white" type="submit">
        {{ $t("Log In") }}
      </button>
      <span>{{ $t("Or") }}</span>
      <button class="input red-back white">
        {{ $t("Log In with Google") }}
      </button>
      <button class="input fc-blue-back white">
        {{ $t("Log In with Facebook") }}
      </button>
      <div class="flex space-btw">
        <p>
          {{ $t("Don't have an account?") }}

          <router-link to="/SignUp" class="orange2">
            {{ $t("Sign Up") }}
          </router-link>
        </p>
        <p>
          <router-link to="/" class="orange2">
            {{ $t("Skip") }}
          </router-link>
        </p>
      </div>
    </form>
    <!-- <div class="sign sign-in"></div> -->
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  export default {
    name: "SignIn",
    components: {},
    data() {
      return {
        email: "",
        password: "",
      };
    },
    methods: {
      ...mapActions(["login"]),
      keepme(e) {
        this.$store.state.auth.keepme = e.target.checked;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/sign.scss";
</style>
