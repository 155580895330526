<template>
  <div>
    <h2>{{$t("Payment method")}}</h2>
    <p>{{$t("Docura fast food")}}</p>
    <div class="to-right">
      <label class="container flex">
        <div class="flex align">
          <img class="icon" src="../assets/restraunts.svg" alt="" />
          
          {{$t("On delivery")}}
        </div>
        <input
          type="radio"
          checked="checked"
          name="shipping-co"
          value="on_delivery"
          v-model="selectedMethod"
          @change="SelectMethod"
        />
        <span class="checkmark"></span>
      </label>
      <label class="container flex">
        <div class="flex align">
          <img class="icon" src="../assets/wallet.png" alt="" />
          
          {{$t("Wallet")}}
        </div>
        <input
          disabled
          type="radio"
          name="shipping-co"
          value="wallet"
          v-model="selectedMethod"
          @change="SelectMethod"
        />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Payment",
  components: {},
  data() {
    return {
      selectedMethod: null,
      methodToPass: null,
    };
  },
  methods: {
    SelectMethod() {
      if (this.selectedMethod == "on_delivery") {
        this.methodToPass = {
          method: "on_delivery",
          photo: require("@/assets/restraunts.svg"),
          description: "الدفع عند الإستلام",
        };
      } else {
        this.methodToPass = {
          method: "wallet",
          photo: require("@/assets/wallet.png"),
          description: "المحفظة",
        };
      }

      this.$store.state.auth.selectedMethod = this.methodToPass;
    },
  },
  mounted() {
    this.$store.state.auth.selectedMethod = null;
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/globalsVars.scss";
@import "../styles/addresses.scss";
</style>
