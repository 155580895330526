import { render, staticRenderFns } from "./Course.vue?vue&type=template&id=a844532a&scoped=true"
import script from "./Course.vue?vue&type=script&lang=js"
export * from "./Course.vue?vue&type=script&lang=js"
import style0 from "./Course.vue?vue&type=style&index=0&id=a844532a&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../.yarn/__virtual__/vue-loader-virtual-723514ec82/2/.yarn/berry/cache/vue-loader-npm-15.11.1-829412283a-10c0.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a844532a",
  null
  
)

export default component.exports