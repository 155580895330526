<template>
  <div>
    <Loader v-if="!$store.state.auth.offers"></Loader>
    <div v-else>
      <div class="splitter">
        <div class="inner"></div>
      </div>
      <h2 class="title padding2">{{ $t("Offers list") }}</h2>
      <div class="flex wrap items-container">
        <div
          v-for="offer in $store.state.auth.offers"
          :to="`shop`"
          :key="offer.id"
          class="item"
          data-aos="fade-left"
        >
          <img :src="offer.photo" alt="" loading="lazy" />
          {{ offer.x }}
          <h3>{{ offer.name }}<br />{{ offer.name }}</h3>
        </div>
      </div>
      <div class="flex center" v-if="$store.state.auth.nextpageInOffers">
        <button
          class="input getmore-button orange3-back white"
          @click="getmore()"
        >
          {{ $t("Get more offers") }}
        </button>
      </div>
    </div>
    <SubLoader v-if="loading"></SubLoader>
  </div>
</template>

<script>
  import axios from "axios";
  import Loader from "../components/Loader";
  import SubLoader from "../components/SubLoader";

  export default {
    name: "Offers",
    components: { Loader, SubLoader },
    data() {
      return {
        loading: false,
      };
    },
    methods: {
      getmore() {
        this.loading = true;
        axios
          .get(`${this.$store.state.auth.nextpageInOffers}`, {
            headers: {
              "x-localization": this.$i18n.locale,
            },
          })
          .then((response) => {
            this.$store.state.auth.offers = this.$store.state.auth.offers.concat(
              response.data.data.items
            );
            this.$store.state.auth.nextpageInOffers =
              response.data.data.paginate.next_page_url;
            this.loading = false;
          });
      },
    },
    mounted() {
      //****************************** getting offers ******************************
      // Simple GET request using axios
      axios
        .get(`${this.$store.state.auth.rootURL}offers`, {
          headers: {
            "x-localization": this.$i18n.locale,
          },
        })
        .then((response) => {
          this.$store.state.auth.offers = response.data.data.items;
          this.$store.state.auth.nextpageInOffers =
            response.data.data.paginate.next_page_url;
        });
    },
  };
</script>

<style lang="sass" scoped>
  @import "../styles/globalsVars.scss"
  @import "../styles/offers.scss"
</style>
