<template>
  <ul class="flex">
    <li v-for="star in 5" :class="{ active: star <= rate }" :key="star.rate">
      <i :class="star <= rate ? 'fas fa-star' : ''"></i>
    </li>
  </ul>
</template>

<script>
export default {
  name: "rate",
  props: ["rate"],
  computed: {},
};
</script>
