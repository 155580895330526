<template>
  <div>
    <Loader
      v-if="!($store.state.auth.courses && $store.state.auth.coursesbyRate)"
    ></Loader>
    <div v-else>
      <div class="splitter">
        <div class="inner"></div>
      </div>
      <div class="flex space-btw title-container">
        <h2 class="title">{{ $t("Docura") }}</h2>
        <button class="blue2-back white input" @click="getTrainers()">
          {{ $t("Trainers") }}
        </button>
      </div>
      <div class="flex items-container wrap">
        <SingleTraining
          v-for="course in $store.state.auth.courses"
          :key="course.id"
          :course="course"
        ></SingleTraining>
      </div>
      <div class="flex center" v-if="$store.state.auth.nextpageInCourses">
        <button
          class="input getmore-button blue4-back white"
          @click="getmore()"
        >
          {{ $t("Get more courses") }}
        </button>
      </div>
      <div class="splitter">
        <div class="inner"></div>
      </div>
      <h2 class="title padding2">{{ $t("Latest Courses") }}</h2>
      <div class="flex items-container wrap">
        <SingleTraining
          v-for="course in $store.state.auth.coursesbyRate"
          :key="course.id"
          :course="course"
        ></SingleTraining>
      </div>
    </div>
    <SubLoader v-if="loading"></SubLoader>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import axios from "axios";
  import Loader from "../components/Loader";
  import SubLoader from "../components/SubLoader";
  import SingleTraining from "../components/SingleTraining";
  export default {
    name: "Trainings",
    components: { SingleTraining, Loader, SubLoader },
    data() {
      return {
        loading: false,
      };
    },
    methods: {
      ...mapActions(["getCourses", "getCoursesByRate"]),
      getmore() {
        this.loading = true;

        axios
          .get(`${this.$store.state.auth.nextpageInCourses}`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          })
          .then((response) => {
            this.$store.state.auth.courses = this.$store.state.auth.courses.concat(
              response.data.data.items
            );
            this.$store.state.auth.nextpageInCourses =
              response.data.data.paginate.next_page_url;
            this.loading = false;
          });
      },
      getTrainers() {
        this.$router.push("/Trainers");
      },
    },
    mounted() {
      this.getCourses();
      this.getCoursesByRate();
    },
  };
</script>
<style lang="sass" scoped>
  @import "../styles/globalsVars.scss"
  @import "../styles/trainings.scss"
</style>
