<template>
  <div class="flex container center">
    <form
      class="flex col"
      @submit.prevent="
        resetPassword({
          email: $store.state.auth.mail,
          code: $store.state.auth.code,
          password: password,
          password_confirmation: password_confirmation,
        })
      "
    >
      <h1>
        <router-link to="/">
          <img src="../assets/logo/full-logo.png" alt="logo" />
        </router-link>
      </h1>
      <h2 class="blue">
        {{ $t("Reset Password") }}
      </h2>

      <input
        class="input"
        type="password"
        name=""
        :placeholder="$t('Password')"
        v-model="password"
        required
      />
      <input
        class="input"
        type="password"
        name=""
        :placeholder="$t('Password Confirmation')"
        v-model="password_confirmation"
        required
      />

      <button class="input blue2-back white" type="submit">
        {{ $t("Save") }}
      </button>
    </form>
    <!-- <div class="sign change-password"></div> -->

    <div v-if="this.$store.state.auth.resetPassword">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal">
              <img src="../assets/like.svg" alt="" />
              <h3 class="blue">
                {{ $t("Your Password was reset successfully") }}
              </h3>
              <p>
                {{ $t("Welcome back to Docura") }}
              </p>
              <button
                class="input blue2-back white"
                @click="finalizeResettingPass"
              >
                {{ $t("Go to your personal account") }}
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  export default {
    name: "ChangePassword",
    components: {},
    data() {
      return {
        password: null,
        password_confirmation: null,
      };
    },
    methods: {
      ...mapActions(["resetPassword", "finalizeResettingPass"]),
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/sign.scss";
</style>
