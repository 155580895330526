import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

//////////////////////////////////// impoting layouts ////////////////////////////////////

import Layout from "../views/Layout";
import CartLayout from "../views/CartLayout";

//////////////////////////////////// impoting Components ////////////////////////////////////

import WhoAreWe from "../components/WhoAreWe";
import TermsCondition from "../components/TermsCondition";
import ContactUs from "../components/ContactUs";
import Home from "../components/Home";
import SignUp from "../components/SignUp";
import SignUpWMail from "../components/SignUpWMail";
import SignIn from "../components/SignIn";
import ForgotPassword from "../components/ForgotPassword";
import Offers from "../components/Offers";
import Shops from "../components/Shops";
import Trainings from "../components/Trainings";
import ChangePassword from "../components/ChangePassword";
import ConfirmationCode from "../components/ConfirmationCode";
import Trainers from "../components/Trainers";
import Course from "../components/Course";
import Notifications from "../components/Notifications";
import Profile from "../components/Profile";
import EditProfile from "../components/EditProfile";
import FavShops from "../components/FavShops";
import Talabaty from "../components/Talabaty";
import Wallet from "../components/Wallet";
import ShopPage from "../components/ShopPage";
import CartProducts from "../components/CartProducts";
import Addresses from "../components/Addresses";
import Delivery from "../components/Delivery";
import Payment from "../components/Payment";
import FinishOrder from "../components/FinishOrder";
import MyCourses from "../components/MyCourses";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    component: Layout,
    children: [
      {
        path: "/",
        component: Home,
        meta: {
          title: "Home",
        },
      },
      {
        path: "WhoAreWe",
        component: WhoAreWe,
        meta: {
          title: "Who are we",
        },
      },
      {
        path: "TermsCondition",
        component: TermsCondition,
        meta: {
          title: "Terms & Conditions",
        },
      },
      {
        path: "ContactUs",
        component: ContactUs,
        meta: {
          title: "Contact Us",
        },
      },
      {
        path: "Offers",
        component: Offers,
        meta: {
          title: "Offers",
        },
      },
      {
        path: "Shops",
        component: Shops,
        meta: {
          title: "Shops",
        },
      },
      {
        path: "Trainings",
        component: Trainings,
        meta: {
          title: "Courses",
        },
      },
      {
        path: "Trainers",
        component: Trainers,
        meta: {
          title: "Trainers",
        },
      },
      {
        path: "Course/:id",
        name: "course",
        component: Course,
        meta: {
          title: "Course",
        },
      },
      {
        path: "Notifications",
        component: Notifications,
        meta: {
          title: "Notifications",
          requiresAuth: true,
        },
      },
      {
        path: "profile",
        component: Profile,
        meta: {
          title: "Profile",
          requiresAuth: true,
        },
      },
      {
        path: "editprofile",
        component: EditProfile,
        meta: {
          title: "Edit Profile",
          requiresAuth: true,
        },
      },
      {
        path: "FavShops",
        component: FavShops,
        meta: {
          title: "Favorite Shops",
          requiresAuth: true,
        },
      },
      {
        path: "talabaty",
        component: Talabaty,
        meta: {
          title: "My orders",
          requiresAuth: true,
        },
      },
      {
        path: "MyCourses",
        component: MyCourses,
        meta: {
          title: "My courses",
          requiresAuth: true,
        },
      },
      {
        path: "wallet",
        component: Wallet,
        meta: {
          title: "Wallet",
          requiresAuth: true,
        },
      },
      {
        path: "shop/:id",
        name: "shop",
        meta: {
          title: "Shop",
        },
        component: ShopPage,
      },
      {
        path: "/don't got to this url",
        component: CartLayout,
        children: [
          {
            path: "/CartProducts",
            name: "CartProducts",
            component: CartProducts,
            meta: {
              title: "Cart products",
              requiresAuth: true,
            },
          },
          {
            path: "/Addresses",
            name: "Addresses",
            component: Addresses,
            meta: {
              title: "Addresses",
              requiresAuth: true,
            },
          },
          {
            path: "/Delivery",
            name: "Delivery",
            component: Delivery,
            meta: {
              title: "Delivery",
              requiresAuth: true,
            },
          },
          {
            path: "/Payment",
            name: "Payment",
            component: Payment,
            meta: {
              title: "Payment",
              requiresAuth: true,
            },
          },
          {
            path: "/FinishOrder",
            name: "FinishOrder",
            component: FinishOrder,
            meta: {
              title: "Finish order",
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },

  {
    path: "/SignUp",
    name: "SignUp",
    component: SignUp,
    meta: {
      requirestoBelogedOut: true,
    },
  },
  {
    path: "/SignUpWMail",
    name: "SignUpWMail",
    component: SignUpWMail,
    meta: {
      requirestoBelogedOut: true,
    },
  },
  {
    path: "/SignIn",
    name: "SignIn",
    component: SignIn,
    meta: {
      requirestoBelogedOut: true,
    },
  },
  {
    path: "/ForgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      requirestoBelogedOut: true,
    },
  },
  {
    path: "/ChangePassword",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/ConfirmationCode",
    name: "ConfirmationCode",
    component: ConfirmationCode,
    meta: {
      requirestoBelogedOut: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} || Docura` : "Docura";
  const isAuthenticated = store.getters["isLoggedIn"];
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next("/SignIn");
    } else {
      next();
    }
  } else {
    next();
  }
  if (to.matched.some((record) => record.meta.requirestoBelogedOut)) {
    if (isAuthenticated) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
