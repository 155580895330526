<template>
  <div class="loader sub-loader flex center align">
    <div class="lds-roller sub-lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubLoader",
};
</script>

<style lang="scss" scoped>
@import "../styles/globalsVars.scss";
@import "../styles/loader.scss";
</style>
