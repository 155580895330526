<template>
  <div>
    <h2>
      {{ $t("Products in cart") }}
    </h2>
    <p>
      {{ $t("Docura fast food") }}
    </p>
    <div class="product-container">
      <div
        class="product flex align space-btw"
        v-for="product in this.$store.state.auth.cartProducts"
        :key="product.product.id"
      >
        <img :src="product.product.photo" alt="product img" />
        <div>
          <h4>{{ product.product.name }}</h4>
          <p class="blue">
            {{ product.product.price }}
            {{ $t("SR") }}
          </p>
          <p>
            {{ $t("Total price of product") }} :
            {{ product.product.price * product.qty }}
            {{ $t("SR") }}
          </p>
        </div>
        <div class="flex align center">
          <button type="button" class="add-minus" @click="increment(product)">
            +
          </button>
          <input type="number" name="" id="" min="1" :value="product.qty" />
          <button type="button" class="add-minus" @click="decrement(product)">
            -
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CartProducts",
    components: {},

    methods: {
      increment(product) {
        this.$store.state.auth.cartProducts[
          this.$store.state.auth.cartProducts.indexOf(product)
        ].qty++;
        let cart = JSON.stringify(this.$store.state.auth.cartProducts);
        window.localStorage.setItem("cart", cart);
        this.$store.state.auth.totalprice =
          parseFloat(this.$store.state.auth.totalprice) +
          parseFloat(product.product.price);
      },
      decrement(product) {
        if (
          this.$store.state.auth.cartProducts[
            this.$store.state.auth.cartProducts.indexOf(product)
          ].qty > 1
        ) {
          this.$store.state.auth.cartProducts[
            this.$store.state.auth.cartProducts.indexOf(product)
          ].qty--;
          let cart = JSON.stringify(this.$store.state.auth.cartProducts);
          window.localStorage.setItem("cart", cart);
          this.$store.state.auth.totalprice =
            parseFloat(this.$store.state.auth.totalprice) -
            parseFloat(product.product.price);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/cardProducts.scss";
</style>
