<template>
  <div>
    <Loader v-if="!$store.state.auth.shopsbyCategory"></Loader>
    <div v-else>
      <div class="category">
        <vueper-slides
          arrows
          :bullets="false"
          :touchable="false"
          rtl
          :arrowsOutside="false"
          class="no-shadow"
          :visible-slides="9"
          :slide-ratio="1 / 5"
          slide-multiple
          :gap="1.5"
          :dragging-distance="200"
          :breakpoints="breakpoints"
        >
          <vueper-slide
            v-for="category in $store.state.auth.categories"
            :key="category.id"
            ><template v-slot:content>
              <div
                @click="categoryClicked($event)"
                class="category-container flex col center align"
                :class="{
                  active: category.id == $store.state.auth.activeCategory,
                }"
                :id="category.id"
              >
                <img :src="category.image" alt="" />
                <h2>
                  {{ $t("Category") }}
                  <br />
                  <span class="light-font"> {{ category.name }}</span>
                </h2>
              </div>
            </template></vueper-slide
          >
        </vueper-slides>
      </div>
      <div class="flex items-container wrap padding2">
        <SingleShop
          v-for="shop in $store.state.auth.shopsbyCategory"
          :key="shop.id"
          :shop="shop"
        ></SingleShop>
      </div>
      <div class="flex center" v-if="$store.state.auth.nextpageInshops">
        <button
          class="input getmore-button orange3-back white"
          @click="getmore()"
        >
          {{ $t("Get more stores") }}
        </button>
      </div>
    </div>
    <SubLoader v-if="$store.state.auth.subloader"></SubLoader>
  </div>
</template>

<script>
  import axios from "axios";
  import Loader from "../components/Loader";
  import SubLoader from "../components/SubLoader";
  import { mapActions } from "vuex";
  import SingleShop from "../components/SingleShop";
  import { VueperSlides, VueperSlide } from "vueperslides";
  import "vueperslides/dist/vueperslides.css";
  export default {
    name: "Shops",
    components: { Loader, SubLoader, SingleShop, VueperSlides, VueperSlide },
    data() {
      return {
        loading: false,
        breakpoints: {
          960: {
            visibleSlides: 6,
            slideRatio: 1 / 4,
          },
          800: {
            visibleSlides: 6,
            slideRatio: 1 / 3,
          },
          660: {
            visibleSlides: 4,
            slideRatio: 1 / 2,
          },
          380: {
            visibleSlides: 2,
            slideRatio: 1 / 1.6,
          },
        },
      };
    },
    methods: {
      ...mapActions(["getshopsbyCategory", "getCategories"]),
      getmore() {
        this.loading = true;

        axios
          .get(`${this.$store.state.auth.nextpageInshops}`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          })
          .then((response) => {
            this.$store.state.auth.shopsbyCategory = this.$store.state.auth.shopsbyCategory.concat(
              response.data.data.items
            );
            this.$store.state.auth.nextpageInshops =
              response.data.data.paginate.next_page_url;
            this.loading = false;
          });
      },
      categoryClicked(e) {
        this.$store.state.auth.subloader = true;
        if (!(e.currentTarget.id == this.$store.state.auth.activeCategory)) {
          this.getshopsbyCategory(e.currentTarget.id);
        }
      },
    },
    mounted() {
      this.getCategories();
    },
  };
</script>
<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/shops.scss";
</style>
