<template>
  <div class="cart">
    <h3 class="flex align space-btw">
      <span>
        {{ $t("Cart") }}
      </span>
      <img src="../assets/cart.svg" alt="" />
    </h3>

    <div v-if="this.$store.state.auth.cartProducts[0]">
      <p class="blue">
        {{ $t("Cart of Docura") }}
      </p>
      <div
        v-for="product in this.$store.state.auth.cartProducts"
        :key="product.product.id"
        class="cart-item flex"
      >
        <img :src="product.product.photo" alt="" />
        <div>
          <h4>{{ product.product.name }}</h4>
          <p class="orange">
            {{ product.product.price }}
            {{ $t("SR") }}
          </p>
          <p>
            {{ $t("Total price of product") }} :
            {{ product.product.price * product.qty }}
            {{ $t("SR") }}
          </p>
        </div>
      </div>
      <div class="billing-details">
        <div class="flex space-btw">
          <p>{{ $t("Total Price of Products") }}</p>
          <p>{{ totalProducts }} {{ $t("SR") }}</p>
        </div>
        <div v-if="this.$store.state.auth.delivery" class="flex space-btw">
          <p>
            {{ $t("Delivery Price") }}
          </p>
          <p>
            {{
              parseFloat(this.$store.state.auth.delivery) *
              ((100 - parseFloat(this.$store.state.auth.deliveryDiscount)) /
                100)
            }}
            {{ $t("SR") }}
          </p>
        </div>
        <!-- <div class="flex space-btw">
          <p>{{ $t("Tax") }}</p>
          <p>
            {{
              (this.$store.state.auth.totalprice *
                this.$store.state.auth.settings.tax) /
              100
            }}
            {{ $t("SR") }}
          </p>
        </div> -->
        <div class="flex space-btw">
          <p class="orange">{{ $t("Total price") }}</p>
          <p class="bold">
            {{ finalPrice }}
            {{ $t("SR") }}
          </p>
        </div>
        <router-link
          to="/CartProducts"
          class="blue flex center"
          @click.native="close"
          >{{ $t("Cart") }}</router-link
        >
      </div>
    </div>

    <div v-else>
      <p>{{ $t("The cart is empty") }}</p>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: "NotificationDrop",
    components: {},
    methods: {
      close() {
        this.$emit("close");
      },
    },
    computed: mapGetters(["totalProducts", "tax", "finalPrice"]),
  };
</script>
