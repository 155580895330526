<template>
  <div>
    <Loader
      v-if="
        !($store.state.auth.storesbyRate && $store.state.auth.coursesbyRate)
      "
    ></Loader>
    <div v-else>
      <!-- \\\\\\\\\\\\ the top photo slider /////////////// -->
      <div>
        <vueper-slides
          :rtl="this.$i18n.locale == 'ar'"
          autoplay
          :touchable="false"
y        >
          <vueper-slide
            v-for="(slide, i) in slides"
            :key="i"
            :image="slide.image"
          />
        </vueper-slides>
      </div>
      <!-- \\\\\\\\\\\\ End of the top photo slider /////////////// -->

      <div class="splitter">
        <div class="inner"></div>
      </div>
      <h2 class="title title-center">{{ $t("Top rated Stores") }}</h2>
      <!-- \\\\\\\\\\\\ the top rated stores slider /////////////// -->
      <div class="multiple">
        <vueper-slides
          :rtl="this.$i18n.locale == 'ar'"
          arrows
          autoplay
          :bullets="false"
          :arrowsOutside="false"
          class="no-shadow"
          :visible-slides="4"
          slide-multiple
          :gap="3"
          :slide-ratio="1 / 3.5"
          :dragging-distance="200"
          :breakpoints="breakpoints"
          :duration="2000"
        >
          <vueper-slide
            v-for="(shop, i) in $store.state.auth.storesbyRate"
            :key="i"
            ><template v-slot:content>
              <SingleShop :shop="shop"></SingleShop> </template
          ></vueper-slide>
        </vueper-slides>
      </div>
      <div class="splitter">
        <div class="inner"></div>
      </div>
      <h2 class="title title-center">{{ $t("Top rated Course") }}</h2>
      <!-- \\\\\\\\\\\\ the Training slider /////////////// -->
      <div class="multiple">
        <vueper-slides
          arrows
          :bullets="false"
          :rtl="this.$i18n.locale == 'ar'"
          :arrowsOutside="false"
          class="no-shadow"
          :visible-slides="4"
          slide-multiple
          :gap="3"
          :slide-ratio="1 / 3"
          :dragging-distance="200"
          :breakpoints="breakpoints2"
        >
          <vueper-slide
            v-for="course in $store.state.auth.coursesbyRate"
            :key="course.id"
            ><template v-slot:content>
              <SingleTraining :course="course"></SingleTraining> </template
          ></vueper-slide>
        </vueper-slides>
      </div>
      <!-- \\\\\\\\\\\\ the end of the Training slider /////////////// -->
    </div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import Loader from "../components/Loader";
  import { VueperSlides, VueperSlide } from "vueperslides";
  import "vueperslides/dist/vueperslides.css";
  import SingleShop from "./SingleShop";
  import SingleTraining from "./SingleTraining";
  export default {
    name: "Home",
    components: {
      VueperSlides,
      VueperSlide,
      SingleShop,
      SingleTraining,
      Loader,
    },
    data() {
      return {
        slides: [
          {
            image: require("@/assets/Group2617.png"),
          },
          {
            image: require("@/assets/Group2617.png"),
          },
          {
            image: require("@/assets/Group2617.png"),
          },
        ],
        breakpoints: {
          1201: {
            visibleSlides: 3,
            slideRatio: 1 / 2.45,
          },
          701: {
            visibleSlides: 2,
            slideRatio: 1 / 1.7,
          },
          441: {
            visibleSlides: 1,
            slideRatio: 1 / 0.9,
          },
        },
        breakpoints2: {
          1201: {
            visibleSlides: 3,
            slideRatio: 1 / 2.25,
          },
          701: {
            visibleSlides: 2,
            slideRatio: 1 / 1.4,
          },
          441: {
            visibleSlides: 1,
            slideRatio: 1 / 0.75,
          },
        },
      };
    },
    methods: {
      ...mapActions(["getStoresByRate", "getCoursesByRate"]),
    },
    mounted() {
      this.getStoresByRate();
      this.getCoursesByRate();
    },
  };
</script>
