<template>
  <div class="profile">
    <ul>
      <li>
        <router-link to="/Profile" @click.native="close"
          ><img src="../assets/profile.png" alt="" />
          {{ $t("Profile account") }}
        </router-link>
      </li>
      <li>
        <router-link to="/FavShops" @click.native="close"
          ><img src="../assets/like.svg" alt="" />
          {{ $t("Favourites") }}
        </router-link>
      </li>
      <li>
        <router-link to="/talabaty" @click.native="close"
          ><img src="../assets/talabaty.svg" alt="" />
          {{ $t("My Orders") }}
        </router-link>
      </li>
      <li>
        <router-link to="/MyCourses" @click.native="close"
          ><img src="../assets/talabaty.svg" alt="" />
          {{ $t("My courses") }}
        </router-link>
      </li>
      <!-- <li>
        <router-link to="/wallet" @click.native="close"
          ><img src="../assets/wallet.png" alt="" />محفظتي</router-link
        >
      </li> -->
      <li>
        <router-link to="/addresses" @click.native="close"
          ><img src="../assets/adresses.svg" alt="" />
          {{ $t("My Addresses") }}
        </router-link>
      </li>
      <li>
        <router-link to="/ContactUs" @click.native="close"
          ><img src="../assets/contact.png" alt="" />
          {{ $t("Contact Us") }}
        </router-link>
      </li>
      <li>
        <router-link to="/" @click.native="logout"
          ><img src="../assets/talabaty.svg" alt="" />
          {{ $t("Log Out") }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    name: "ProfileDrop",
    components: {},
    methods: {
      ...mapActions(["logout"]),
      close() {
        this.$emit("close");
      },
    },
  };
</script>
