<template>
  <div class="cart">
    <h3 class="white black-back">
      {{ $t("Cart summary") }}
    </h3>
    <div class="details-container">
      <div v-if="this.$store.state.auth.cartProducts[0]">
        <div
          v-for="product in this.$store.state.auth.cartProducts"
          :key="product.product.id"
          class="item flex space-btw"
        >
          <div class="flex align">
            <img
              v-if="$route.name == 'CartProducts'"
              src="../assets/minus.png"
              alt=""
              @click="remove(product)"
            />
            <span>x{{ product.qty }}</span>
            <p>{{ product.product.name }}</p>
          </div>
          <p>
            {{ product.product.price * product.qty }}
            {{ $t("SR") }}
          </p>
        </div>
      </div>
      <div v-else>{{ $t("The cart is empty") }}</div>

      <div class="billing-details">
        <div class="flex space-btw">
          <p>{{ $t("Total Price of Products") }}</p>
          <p>
            {{ totalProducts }}
            {{ $t("SR") }}
          </p>
        </div>
        <div v-if="this.$store.state.auth.delivery" class="flex space-btw">
          <p>
            {{ $t("Delivery Price") }}
          </p>
          <p>
            {{
              parseFloat(this.$store.state.auth.delivery) *
              ((100 - parseFloat(this.$store.state.auth.deliveryDiscount)) /
                100)
            }}
            {{ $t("SR") }}
          </p>
        </div>
        <!-- <div class="flex space-btw">
          <p>
            {{ $t("Tax") }}
          </p>
          <p>
            {{
              tax
            }}
            {{ $t("SR") }}
          </p>
        </div> -->
        <div class="flex space-btw">
          <p class="orange">
            {{ $t("Total price") }}
          </p>
          <p class="bold">
            {{ finalPrice }}
            {{ $t("SR") }}
          </p>
        </div>
      </div>
      <!--  -->
      <div v-if="$route.name == 'FinishOrder'">
        <div class="flex space-btw" style="margin-top: 10px">
          <input
            type="text"
            class=""
            style="flex-grow: 1"
            :placeholder="$t('Discount Coupon')"
            v-model="coupon"
            @keydown="couponchanged"
          />
          <button class="white" @click="checkCoupon" style="margin: 0 5px">
            {{$t("Check")}}
          </button>
        </div>
        <p v-if="couponapplied" style="color: #38ae6c">
          {{ $t("Coupon Applied") }}
        </p>
        <p v-if="wrongcoupon" class="orange">
          {{ $t("This coupon is not available") }}
        </p>
        <p v-if="checkFirst" class="orange">
          {{ $t("Check the availabilty First") }}
        </p>
      </div>

      <div
        class="flex center"
        :class="{
          disabled:
            !this.$store.state.auth.cartProducts[0] ||
            (this.$route.name == 'Addresses' &&
              !this.$store.state.auth.selectedAddress) ||
            (this.$route.name == 'Delivery' &&
              !this.$store.state.auth.selectedDelivery) ||
            (this.$route.name == 'Payment' &&
              !this.$store.state.auth.selectedMethod),
        }"
      >
        <button class="white" @click="nextStep()">
          {{
            $route.name == "shop"
              ? $t("Finish payment")
              : $route.name == "FinishOrder"
              ? $t("Finish order")
              : $t("Next")
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapGetters } from "vuex";

  export default {
    name: "CartSummary",
    components: {},
    data() {
      return {
        totla: null,
        coupon: "",
        wrongcoupon: false,
        couponapplied: false,
        checkFirst: false,
      };
    },

    methods: {
      remove(product) {
        this.$store.state.auth.cartProducts.forEach((item) => {
          if (item.product.id == product.product.id) {
            this.$store.state.auth.totalprice -=
              product.product.price * item.qty;
            this.$store.state.auth.cartProducts.splice(
              this.$store.state.auth.cartProducts.indexOf(item),
              1
            );
            let cart = JSON.stringify(this.$store.state.auth.cartProducts);
            window.localStorage.setItem("cart", cart);
          }
        });
        if (!this.$store.state.auth.cartProducts.length) {
          this.$store.state.auth.store_id = this.$route.params.id;
          window.localStorage.setItem("cart_store", this.$route.params.id);
        }
      },
      nextStep() {
        if (this.$route.name == "shop") {
          this.$router.push("/CartProducts");
        } else if (this.$route.name == "CartProducts") {
          this.$router.push("/addresses");
        } else if (this.$route.name == "Addresses") {
          this.$router.push("/Delivery");
        } else if (this.$route.name == "Delivery") {
          this.$router.push("/Payment");
        } else if (this.$route.name == "Payment") {
          this.$router.push("/FinishOrder");
        } else if (this.$route.name == "FinishOrder") {
          if (this.coupon == "" || this.couponapplied) {
            let items_in_cart = [];
            this.$store.state.auth.cartProducts.forEach((item) => {
              items_in_cart.push({ id: item.product.id, qty: item.qty });
            });
            let data = {
              address_id: this.$store.state.auth.selectedAddress.id,
              company_id: this.$store.state.auth.selectedDelivery.id,
              payment_method: this.$store.state.auth.selectedMethod.method,
              store_id: this.$store.state.auth.store_id,
              items: items_in_cart,
            };
            if (this.couponapplied) {
              data.code = parseInt(this.coupon);
            } else {
              delete data.code;
            }

            axios
              .post(`${this.$store.state.auth.rootURL}cart`, data, {
                headers: {
                  Authorization: `Bearer ${this.$store.state.auth.token}`,
                },
              })
              .then((response) => {
                console.log(response);
              });
          } else {
            if (!this.wrongcoupon) {
              this.checkFirst = true;
            }
          }
        }
        // else {
        // }
      },
      couponchanged() {
        this.$store.state.auth.applied_Coupon_Discount = 0;

        this.couponapplied = false;
        this.wrongcoupon = false;
        this.checkFirst = false;
      },
      checkCoupon() {
        if (this.coupon != "") {
          axios
            .post(`${this.$store.state.auth.rootURL}coupons`, {
              code: this.coupon,
            })
            .then((response) => {
              console.log(response);
              if (response.data.status) {
                this.$store.state.auth.applied_Coupon_Discount =
                  response.data.data.value;
                this.coupon;
                this.couponapplied = true;
                this.wrongcoupon = false;
                this.checkFirst = false;
              } else {
                this.$store.state.auth.applied_Coupon_Discount = 0;
                this.couponapplied = false;
                this.wrongcoupon = true;
                this.checkFirst = false;
              }
            });
        } else {
          this.wrongcoupon = false;
          this.couponapplied = false;
        }
      },
    },
    computed: mapGetters(["totalProducts", "tax", "finalPrice"]),
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/cartSummary.scss";
</style>
