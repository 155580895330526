import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as GmapVue from "gmap-vue";
import i18n from "./i18n";
import AnimateCSS from "animate.css";
Vue.use(AnimateCSS);
import AOS from "aos";
import "aos/dist/aos.css";
Vue.config.productionTip = false;

Vue.use(GmapVue, {
  load: {
    key: "AIzaSyBxmTcy67Mwzu-AvUxfw88AhsCmPTnaPF4",
    libraries: "places",
  },
  installComponents: true,
});
Vue.use(i18n);

Vue.directive("click-outside-app", {
  bind: function (el, binding) {
    // Define ourClickEventHandler
    const ourClickEventHandler = (event) => {
      if (
        (!el.contains(event.target) &&
          el !== event.target &&
          !event.target.classList.contains("modal-opener")) ||
        event.target.classList.contains("modal-wrapper")
      ) {
        // as we are attaching an click event listern to the document (below)
        // ensure the events target is outside the element or a child of it
        binding.value(event); // before binding it
      }
    };
    // attached the handler to the element so we can remove it later easily
    el.__vueClickEventHandler__ = ourClickEventHandler;

    // attaching ourClickEventHandler to a listener on the document here
    document.addEventListener("click", ourClickEventHandler);
  },
  unbind: function (el) {
    // Remove Event Listener
    document.removeEventListener("click", el.__vueClickEventHandler__);
  },
});

new Vue({
  created() {
    AOS.init({ useClassNames: true, once: false });
  },
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
