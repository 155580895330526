<template>
  <a
    :href="lec.url"
    target="blank"
    class="lec-Container"
    :class="{ enabled: !enabled }"
  >
    <div class="flex col align">
      <img src="../assets/Group1006.png" alt="" />
      <h3>{{ courseName }}</h3>
      <p class="orange">{{$t("Lesson")}} {{ lec.id }}</p>
    </div></a
  >
</template>

<script>
export default {
  name: "Lec",
  components: {},
  props: ["lec", "courseName", "enabled"],
};
</script>

<style lang="sass" scoped>
@import "../styles/globalsVars.scss"
@import "../styles/Lec.scss"
</style>
