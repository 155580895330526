import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=b48b45d2&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=b48b45d2&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../.yarn/__virtual__/vue-loader-virtual-723514ec82/2/.yarn/berry/cache/vue-loader-npm-15.11.1-829412283a-10c0.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b48b45d2",
  null
  
)

export default component.exports