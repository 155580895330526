<template>
  <div>
    <Loader v-if="!$store.state.auth.orders"></Loader>
    <div v-else>
      <div class="splitter">
        <div class="inner"></div>
      </div>
      <h2 class="title padding2">{{ $t("My Orders") }}</h2>
      <div class="tabs flex center">
        <button
          @click="changeTab('pending')"
          :class="{ active: pendingIsAcive }"
        >
          {{ $t("New") }}
        </button>
        <button
          @click="changeTab('preparing')"
          :class="{ active: preparingIsAcive }"
        >
          {{ $t("Preparing") }}
        </button>
        <button
          @click="changeTab('delivering')"
          :class="{ active: deliveringIsAcive }"
        >
          {{ $t("Delivering") }}
        </button>
        <button
          @click="changeTab('completed')"
          :class="{ active: completedIsAcive }"
        >
          {{ $t("Completed") }}
        </button>
      </div>
      <div class="flex center">
        <table>
          <thead>
            <tr class="">
              <th>
                {{ $t("Store") }}
              </th>
              <th>
                {{ $t("Total price") }}
              </th>
              <th>
                
                {{ $t("Order num.") }}
              </th>
              <th>
                
                {{ $t("Order date") }}
              </th>
              <th>
                
                {{ $t("Stauts") }}
              </th>
              <th v-if="completedIsAcive"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in $store.state.auth.orders"
              :key="order.orderNumber"
            >
              <td class="flex align">
                <div class="img-container flex center align">
                  <img src="../assets/logo/logo.svg" alt="" />
                </div>
                {{ order.store }}
              </td>
              <td>{{ order.grand_total }}</td>
              <td>{{ order.orderNumber }}</td>
              <td>{{ order.date }}</td>
              <td>
                {{ order.status }}
              </td>
              <td v-if="order.status == 'completed'">
                <button
                  @click="toogleRateModal(order.store_id)"
                  class="modal-opener"
                >
                  {{ $t("+ Add a review") }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="rateModal" v-click-outside-app="toogleRateModal">
      <transition name="model">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal">
              <img src="../assets/talabaty.svg" alt="" />
              <h3 class="blue">
                رأيك يهمنا
                {{ $t("Your opinion matters") }}
              </h3>
              <p>
                من فضلك قم بتقييم المتجر
                {{ $t("Please rate this store") }}
              </p>
              <form @submit.prevent="sendReview">
                <GetRate
                  @rated="rated"
                  :grade="1"
                  :maxStars="5"
                  :hasCounter="false"
                ></GetRate>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  v-model="review"
                  required
                ></textarea>
                <button class="input blue2-back white" type="submit">
                  {{ $t("Send") }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapActions } from "vuex";
  import Loader from "../components/Loader";
  import GetRate from "./GetRate";

  export default {
    name: "Talabaty",
    components: { Loader, GetRate },
    data() {
      return {
        pendingIsAcive: true,
        preparingIsAcive: false,
        deliveringIsAcive: false,
        completedIsAcive: false,
        rateModal: false,
        review: "",
        rate: "",
        storeId: null,
      };
    },
    methods: {
      ...mapActions(["getOrders"]),
      changeTab(view) {
        switch (view) {
          case "pending":
            this.pendingIsAcive = true;
            this.preparingIsAcive = false;
            this.deliveringIsAcive = false;
            this.completedIsAcive = false;
            break;
          case "preparing":
            this.pendingIsAcive = false;
            this.preparingIsAcive = true;
            this.deliveringIsAcive = false;
            this.completedIsAcive = false;
            break;
          case "delivering":
            this.pendingIsAcive = false;
            this.preparingIsAcive = false;
            this.deliveringIsAcive = true;
            this.completedIsAcive = false;
            break;
          case "completed":
            this.pendingIsAcive = false;
            this.preparingIsAcive = false;
            this.deliveringIsAcive = false;
            this.completedIsAcive = true;
            break;
          default:
          // code block
        }
        this.getOrders(view);
      },
      toogleRateModal(id) {
        this.rateModal = !this.rateModal;
        this.storeId = id;
      },
      rated(rate) {
        this.rate = rate;
      },
      sendReview() {
        let data = {
          review: this.review,
          rate: this.rate,
          reviewable_id: this.storeId,
          type: "store",
        };

        axios
          .post(`${this.$store.state.auth.rootURL}review`, data, {
            headers: {
              Authorization: `Bearer ${this.$store.state.auth.token}`,
            },
          })
          .then((response) => {
            this.store.reviews.push(response.data.data);
          });

        this.toogleRateModal();
      },
    },
    mounted() {
      this.getOrders("pending");
    },
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/talabaty.scss";
</style>
