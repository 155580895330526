<template>
  <div>
    <Loader v-if="!$store.state.auth.settings"></Loader>
    <div id="app" v-else>
      <router-view />
      <!-- ////////////////////////\\\\\\\\\\\\\\\\\\ -->
      <div v-if="this.$store.state.auth.error" v-click-outside-app="closeError">
        <transition name="model">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal">
                <p>{{ $store.state.auth.msg }}</p>
                <router-link v-if="this.$store.state.auth.errorToSign" to="/SignIn" class="orange2" @click.native="closeError">
                  {{ $t("Log In") }}
                </router-link>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!-- ////////////////////////\\\\\\\\\\\\\\\\\\ -->
      <div
        v-if="this.$store.state.auth.registeredAcc"
        v-click-outside-app="finalizeSignup"
      >
        <transition name="model">
          <div class="modal-mask">
            <div class="modal-wrapper">
              <div class="modal">
                <img src="./assets/like.svg" alt="" />
                <h3 class="blue">تم انشاء حساب بنجاح</h3>
                <p>أهلا بك في تطبيق دوسورا</p>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Loader from "./components/Loader.vue";
  import { mapActions } from "vuex";
  export default {
    name: "App",
    data() {
      return {
        user: null,
      };
    },
    components: { Loader },
    methods: {
      ...mapActions(["closeError", "finalizeSignup", "getSettings"]),
    },

    mounted() {
      let totalTabs = Number;

      window.onload = function () {
        if (window.sessionStorage.getItem("token")) {
          window.localStorage.setItem(
            "token",
            window.sessionStorage.getItem("token")
          );
          window.sessionStorage.removeItem("token");
        }
        if (localStorage.getItem("count") === null) {
          totalTabs = 0;
        } else {
          totalTabs = parseInt(localStorage.getItem("count"));
        }
        totalTabs++;
        localStorage.setItem("count", totalTabs);
      };

      window.onbeforeunload = function () {
        if (localStorage.getItem("count") === null) {
          totalTabs = 1;
        } else {
          totalTabs = parseInt(localStorage.getItem("count"));
        }
        totalTabs--;
        localStorage.setItem("count", totalTabs);
        if (totalTabs < 1) {
          if (window.localStorage.getItem("kept") == "false") {
            window.sessionStorage.setItem(
              "token",
              window.localStorage.getItem("token")
            );
            window.localStorage.removeItem("token");
          }
        }
      };

      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      if (!JSON.parse(window.localStorage.getItem("cart"))) {
        this.$store.state.auth.cartProducts = [];
      } else {
        this.$store.state.auth.cartProducts = JSON.parse(
          window.localStorage.getItem("cart")
        );
        this.$store.state.auth.cartProducts.forEach((item) => {
          let priceOfItem = item.qty * item.product.price;
          this.$store.state.auth.totalprice += priceOfItem;
        });
      }
      this.getSettings();
      axios.get(`${this.$store.state.auth.rootURL}cities`).then((response) => {
        this.$store.state.auth.cities = response.data.data;
      });
    },
  };
</script>

<style lang="scss">
  @import "./styles/globalsVars.scss";
  @import "./styles/public-style.scss";
</style>
