<template>
  <div class="loader flex center align">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Loader",
    components: {},
  };
</script>

<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/loader.scss";
</style>
