<template>
  <div class="container">
    <div class="splitter">
      <div class="inner"></div>
    </div>
    <h2 class="title title-center">{{ $t("Contact Us") }}</h2>
    <form action="" class="flex col" @submit.prevent="send">
      <input
        class="input"
        type="text"
        name="name"
        :placeholder="$t('name')"
        v-model="name"
      />
      <input
        class="input"
        type="email"
        name="email"
        :placeholder="$t('Email')"
        v-model="email"
      />
      <textarea
        name="msg"
        cols="30"
        rows="10"
        :placeholder="$t('Messege content')"
        v-model="msg"
      ></textarea>
      <button type="submit" class="input orange-back white">
        {{$t("Send")}}
      </button>
    </form>
  </div>
</template>

<script>
  import axios from "axios";
  export default {
    name: "ContactUs",
    components: {},
    data() {
      return {
        name: "",
        email: "",
        msg: "",
      };
    },
    methods: {
      send() {
        let data = { name: this.name, email: this.email, message: this.msg };
        axios.post(`${this.$store.state.auth.rootURL}contact`, data);
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import "../styles/globalsVars.scss";
  @import "../styles/Details.scss";
</style>
